import React, { useMemo } from 'react'
import { Flex, TooltipText, Skeleton, useTooltip, Box, Text } from '@unitydefi/uikit'
import { useTranslation } from 'contexts/Localization'
import { DeserializedNftPool } from 'state/types'
import { getBalanceNumber } from 'utils/formatBalance'
import styled from 'styled-components'

interface FeeRowRowProps {
  pool: DeserializedNftPool
}

const TitleContainer = styled.span`
  font-size: 0.875rem;
`

const FeeRowRow: React.FC<FeeRowRowProps> = ({ pool }) => {
  const { t } = useTranslation()
  const tooltipContent = t('This fee is only paid 1 time during you first staking')

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-start' })
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        {tooltipVisible && tooltip}
        <TooltipText ref={targetRef}>
          <TitleContainer>{`${t('REGISTRATION FEE')}:`}</TitleContainer>
        </TooltipText>
        {pool.registrationFee ? (
          <div>{getBalanceNumber(pool.registrationFee, 18)} BNB</div>
        ) : (
          <Skeleton width="82px" height="32px" />
        )}
      </Flex>
    </>
  )
}

export default FeeRowRow
