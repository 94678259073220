import React from 'react'
import { Link, Text } from '@unitydefi/uikit'
import { getExplorerLink } from 'utils'
import useSelectedChain from 'hooks/useSelectedChain'
import truncateHash from 'utils/truncateHash'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
}

const DescriptionWithTx: React.FC<DescriptionWithTxProps> = ({ txHash, children }) => {
  const { selectedChain } = useSelectedChain()

  return (
    <>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link external href={getExplorerLink(txHash, 'transaction', selectedChain.chainId)}>
          View on {selectedChain.explorerName}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx
