import { SUPPORTED_CHAINS, SupportedChainConfiguration, SupportedChainId, WETH_TOKENS } from 'config/constants/chains'
import { API_EXTENSIONS_DEX_POOLS } from 'config/constants/endpoints'
import pools from 'config/constants/pools'
import priceHelperPoolLps from 'config/constants/priceHelperPoolLps'
import { serializeTokens } from 'config/constants/tokens'
import { PoolCategory } from 'config/constants/types'

const serializedTokens = serializeTokens()

export const fetchExtensionPools = async () => {
  try {
    const result = await fetch(API_EXTENSIONS_DEX_POOLS)
    if (result.ok) {
      const data: {
        tokens: { [address: string]: any }
        pools: { [address: string]: any }
        lpHelper: { [address: string]: string }
      } = await result.json()

      const maxPoolId = pools.sort((a, b) => b.sousId - a.sousId)[0].sousId

      Object.values(data.pools).forEach((pool, idx) => {
        const chainId: SupportedChainId = pool.chainId || SupportedChainId.MAINNET
        if (pools.find((p) => p.contractAddress[chainId] === pool.address)) return

        let stakingToken =
          serializedTokens[chainId][pool.stakingToken] ||
          Object.values(serializedTokens[chainId]).find((p) => p.address === pool.stakingToken)
        if (!stakingToken) {
          const token = data.tokens[pool.stakingToken]
          if (token) {
            stakingToken = {
              address: pool.stakingToken,
              chainId,
              decimals: token.decimals,
              name: token.name,
              symbol: token.symbol,
              projectLink: token.projectLink,
              imageUrl: token.imageUrl,
            }
            serializedTokens[chainId][pool.stakingToken] = stakingToken
          }
        }

        let earningToken =
          serializedTokens[chainId][pool.earningToken] ||
          Object.values(serializedTokens[chainId]).find((p) => p.address === pool.earningToken)
        if (!earningToken) {
          const token = data.tokens[pool.earningToken]
          if (token) {
            earningToken = {
              address: pool.earningToken,
              chainId,
              decimals: token.decimals,
              name: token.name,
              symbol: token.symbol,
              projectLink: token.projectLink,
              imageUrl: token.imageUrl,
            }
            serializedTokens[chainId][pool.earningToken] = earningToken
          }
        }

        pools.push({
          sousId: maxPoolId + idx + 1, // pool.poolId,
          contractAddress: { [chainId]: pool.address },
          poolCategory: PoolCategory.CORE,
          stakingToken,
          earningToken,
          sortOrder: 999,
          tokenPerBlock: pool.rewardPerBlock,
          tax: pool.tax,
          projectTax: pool.projectTax,
          isLpPool: pool.isLpPool,
          chainId,
        })
      })

      Object.keys(data.lpHelper).forEach((tokenAddress) => {
        const allTokens = Object.values(serializedTokens).flatMap((list) => Object.values(list))
        const token = allTokens.find((p) => p.address === tokenAddress)
        const tokenChain: SupportedChainConfiguration = SUPPORTED_CHAINS[token.chainId]
        const lpAddress = data.lpHelper[tokenAddress]
        const lp = priceHelperPoolLps.find(
          (p) => p.lpAddresses[token.chainId]?.toLowerCase() === lpAddress.toLowerCase(),
        )

        if (!lp) {
          const wbnb = allTokens.find(
            (p) => p.address === WETH_TOKENS[tokenChain.chainId].address && p.chainId === tokenChain.chainId,
          )
          priceHelperPoolLps.push({
            pid: null,
            lpSymbol: `${token.symbol}-BNB LP`,
            lpAddresses: {
              [token.chainId]: lpAddress,
            },
            token,
            quoteToken: wbnb,
            depositFeeBP: 0,
          })
        }
      })
    }
  } catch (e) {
    console.error(e)
  }

  return {
    pools,
    serializedTokens,
  }
}
