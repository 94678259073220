import React from 'react'
import { Button, AutoRenewIcon, Skeleton } from '@unitydefi/uikit'
import { useTranslation } from 'contexts/Localization'
import { useErc721CollectionContract } from 'hooks/useContract'
import { DeserializedNftPool } from 'state/types'
import { useApproveNftPool } from '../../../hooks/useApproveNft'

interface ApprovalActionProps {
  pool: DeserializedNftPool
  isLoading?: boolean
}

const ApprovalAction: React.FC<ApprovalActionProps> = ({ pool, isLoading = false }) => {
  const { sousId, collection } = pool
  const { t } = useTranslation()
  const collectionContract = useErc721CollectionContract(collection.address)
  const { handleApprove, requestedApproval } = useApproveNftPool(collectionContract, sousId)

  return (
    <>
      {isLoading ? (
        <Skeleton width="100%" height="52px" />
      ) : (
        <Button
          isLoading={requestedApproval}
          endIcon={requestedApproval ? <AutoRenewIcon spin color="currentColor" /> : null}
          disabled={requestedApproval}
          onClick={handleApprove}
          width="100%"
        >
          {t('Enable')}
        </Button>
      )}
    </>
  )
}

export default ApprovalAction
