import React from 'react'
import { BinanceIcon, SvgProps } from '@unitydefi/uikit'
import { SupportedChainId } from 'config/constants/chains'
import { BitgertIcon } from './bitgertIcon'
import PulseChainIcon from './pulseChainIcon'
import { EthereumIcon } from './ethIcon'
import PhoenixIcon from './phoenixIcon'

type NativeChainIconProps = SvgProps & {
  chainId: SupportedChainId
}

export function NativeChainIcon({ chainId, ...props }: NativeChainIconProps) {
  switch (chainId) {
    case SupportedChainId.BITGERT:
      return <BitgertIcon {...props} />
    case SupportedChainId.PULSECHAIN:
      return <PulseChainIcon {...props} />
    case SupportedChainId.BASE:
      return <EthereumIcon {...props} />
    case SupportedChainId.PHX:
      return <PhoenixIcon {...props} />
    default:
      return <BinanceIcon {...props} />
  }
}
