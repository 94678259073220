import { SupportedChainId } from './chains'
import { serializeNftCollections } from './collections'
import { serializeTokens } from './tokens'
import { SerializedNftPoolConfig } from './types'

const serializedTokens = serializeTokens()
const serializedCollections = serializeNftCollections()

const nftPools: SerializedNftPoolConfig[] = [
  {
    sousId: 1,
    collection: serializedCollections.revuWarriors,
    earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
    contractAddress: {
      97: '0x9b831e5136d8c440D4bCB0E13e3D5ab6f6159335',
      56: '0x53160c6ac8d3f67D45C2B8ffC119F1abF4C8eB15',
    },
    sortOrder: 1,
    isFinished: false,
    mode: 'duration',
    chainId: 56,
    // requiresRegistration: true,
  },
  // {
  //   sousId: 2,
  //   collection: serializedCollections.collection2,
  //   earningToken: serializedTokens.uv,
  //   contractAddress: {
  //     97: '0xd549217a27fbfAe47e0a61f1D70bFdfb6829a993',
  //     56: '',
  //   },
  //   sortOrder: 1,
  //   isFinished: false,
  // }
]

export default nftPools
