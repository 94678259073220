import { SupportedChainId } from './chains'
import tokens from './tokens'
import { SerializedFarmConfig } from './types'

const priceHelperPoolLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for Pools without a farm.
   */
  {
    pid: null,
    lpSymbol: 'HMNG-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x472D5D9266B5794309c353d703fC1A44A864AC82',
    },
    token: tokens[SupportedChainId.MAINNET].hummingbird,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'USDC-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b',
    },
    token: tokens[SupportedChainId.MAINNET].usdc,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'BPTOKEN-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xbBDc7A6c2d27B3827Db061a451aEA84323B79b51',
    },
    token: tokens[SupportedChainId.MAINNET].bptoken,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'HEGG-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x6cb1338a36830eFC75F6e6cE89897a5a8621a1A8',
    },
    token: tokens[SupportedChainId.MAINNET].hegg,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'SWYFTT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x2eDe2617bF193ef4aC366d59D923FdaF97E1da47',
    },
    token: tokens[SupportedChainId.MAINNET].swyftt,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'VIVA-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xD55496B1d84dfd4614c89ae90fa9835a4B907DEf',
    },
    token: tokens[SupportedChainId.MAINNET].viva,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'FAMY-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xbC89679e0252F05C3184030c11e3f8887c760c70',
    },
    token: tokens[SupportedChainId.MAINNET].famy,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'RYIUV1-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x1B0CC844599e8901703564cF2151b57461563d02',
    },
    token: tokens[SupportedChainId.MAINNET].ryiu,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'WPHX-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x78A1656f1Cbb25cDF662cEFb7b9a9d14C6724Ddc',
    },
    token: tokens[SupportedChainId.MAINNET].phoenix,
    quoteToken: tokens[SupportedChainId.MAINNET].usdc,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'CBLT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xC1dA281839F58F32c48cB096C11b9d881A3EbCB6',
    },
    token: tokens[SupportedChainId.MAINNET].cblt,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
  {
    pid: null,
    lpSymbol: 'RYIU-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xb9ad1F96ac77843347E0BF5DD5B19862EaA9d118',
    },
    token: tokens[SupportedChainId.MAINNET].ryiuv2,
    quoteToken: tokens[SupportedChainId.MAINNET].wbnb,
    depositFeeBP: 0,
  },
]

export default priceHelperPoolLps
