import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ConnectorNames, connectorLocalStorageKey } from '@unitydefi/uikit'
// import { setupNetwork } from 'utils/wallet'
import useToast from 'hooks/useToast'
import { profileClear } from 'state/profile'
import { useAppDispatch } from 'state'
import { useTranslation } from 'contexts/Localization'
import { clearAllTransactions } from 'state/transactions/actions'
import { resetUserNftState } from '../state/nftMarket/reducer'
import { CONNECTORS } from '../lib/wallet'

const useAuth = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { chainId, connector: currentConnector } = useWeb3React()
  const { toastError } = useToast()

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const [connector] = CONNECTORS[connectorID]
      if (connector) {
        connector.activate()

        // connector.activate(async (error: Error) => {
        //   // if (error instanceof UnsupportedChainIdError) {
        //   //   const hasSetup = await setupNetwork()
        //   //   if (hasSetup) {
        //   //     connector.activate()
        //   //   }
        //   // } else {
        //   window.localStorage.removeItem(connectorLocalStorageKey)
        //   toastError(error.name, error.message)
        //   // if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
        //   //   toastError(t('Provider Error'), t('No provider was found'))
        //   // } else if (
        //   //   error instanceof UserRejectedRequestErrorInjected ||
        //   //   error instanceof UserRejectedRequestErrorWalletConnect
        //   // ) {
        //   //   if (connector instanceof WalletConnectConnector) {
        //   //     const walletConnector = connector as WalletConnectConnector
        //   //     walletConnector.walletConnectProvider = null
        //   //   }
        //   //   toastError(t('Authorization Error'), t('Please authorize to access your account'))
        //   // } else {
        //   //   toastError(error.name, error.message)
        //   // }
        //   // }
        // })
      } else {
        toastError(t('Unable to find connector'), t('The connector config is wrong'))
      }
    },
    [t, toastError],
  )

  const logout = useCallback(() => {
    dispatch(profileClear())
    dispatch(resetUserNftState())
    if (currentConnector?.deactivate) {
      currentConnector.deactivate()
    } else if (currentConnector?.resetState) {
      currentConnector.resetState()
    }
    window.localStorage.removeItem(connectorLocalStorageKey)
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }, [currentConnector, dispatch, chainId])

  return { login, logout }
}

export default useAuth
