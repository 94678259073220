import getNodeUrl from 'utils/getRpcUrl'
import { SUPPORTED_CHAINS, SupportedChainId } from 'config/constants/chains'
import { JsonRpcProvider } from '@ethersproject/providers'

const providers: { [key: number]: JsonRpcProvider } = {}

export function getRpcProvider(chainId: number) {
  if (providers[chainId]) return providers[chainId]

  const providerUrl = SUPPORTED_CHAINS[chainId]?.rpcUrl || getNodeUrl()
  providers[chainId] = new JsonRpcProvider(providerUrl)
  return providers[chainId]
}

export const DEFAULT_RPC_PROVIDER = getRpcProvider(SupportedChainId.MAINNET)
