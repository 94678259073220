import { SupportedChainId } from './chains'
import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
    contractAddress: {
      97: '',
      56: '0x2eE006bc0562E5b0ef37C93dCaFA464706f628F7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00875',
    sortOrder: 1,
    isFinished: true,
    chainId: 56,
  },

  {
    sousId: 27,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].cblt,
    earningToken: serializedTokens[SupportedChainId.MAINNET].cblt,
    contractAddress: {
      97: '',
      56: '0x285FAca72C5Abb62a5874a387996E038F031B0E1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.776',
    isFinished: false,
    chainId: 56,
  },

  {
    sousId: 26,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].phoenix,
    contractAddress: {
      97: '',
      56: '0xebb16902005E7De58F3568f10e386cC13Dd26309',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.001157',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 25,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].phoenix,
    earningToken: serializedTokens[SupportedChainId.MAINNET].phoenix,
    contractAddress: {
      97: '',
      56: '0x7560A9520B1355B19DF79c69fa5f61e3DC404Aaa',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.001164',
    isFinished: false,
    chainId: 56,
  },

  {
    sousId: 24,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
    contractAddress: {
      97: '',
      56: '0x8db01031AC39a756363e45c0663De3034a5F89f3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 2,
    tokenPerBlock: '0.0034944670937682005',
    isFinished: false,
    chainId: 56,
  },

  {
    sousId: 23,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].wbnb,
    contractAddress: {
      97: '',
      56: '0x5FD42F093D0b387982D1D6cDA8C205b9B41B8ba0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0000023148',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 22,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].wbnb,
    earningToken: serializedTokens[SupportedChainId.MAINNET].ryiu,
    contractAddress: {
      97: '',
      56: '0xd54d5391062FD08970570D55B08D4C717B3c9a8A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.05787',
    isFinished: false,
    chainId: 56,
  },

  {
    sousId: 17,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv_bnb_lp,
    earningToken: serializedTokens[SupportedChainId.MAINNET].ryiu,
    contractAddress: {
      97: '',
      56: '0x6F2B41dF39738faD6b31f8f29C364fe9B1E776Bf',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1012',
    isFinished: false,
    isLpPool: true,
    chainId: 56,
  },
  {
    sousId: 18,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].ryiu_bnb_lp,
    earningToken: serializedTokens[SupportedChainId.MAINNET].ryiu,
    contractAddress: {
      97: '',
      56: '0x4Fc888830091EF0806165D183eE38BA32f1bbd39',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.1012',
    isFinished: false,
    isLpPool: true,
    chainId: 56,
  },
  {
    sousId: 19,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].viva_bnb_lp,
    earningToken: serializedTokens[SupportedChainId.MAINNET].viva,
    contractAddress: {
      97: '',
      56: '0xF4938E1597EF2c01Eb9B8AC96fb62DF9a1542B06',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '57870',
    isFinished: false,
    isLpPool: true,
    chainId: 56,
  },

  {
    sousId: 20,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].swyftt_bnb_lp,
    earningToken: serializedTokens[SupportedChainId.MAINNET].swyftt,
    contractAddress: {
      97: '',
      56: '0x0350D0Aa131a75A57C5a259b57663F49cF52f0C3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.69',
    isFinished: false,
    isLpPool: true,
    chainId: 56,
  },
  {
    sousId: 21,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].famy_bnb_lp,
    earningToken: serializedTokens[SupportedChainId.MAINNET].famy,
    contractAddress: {
      97: '',
      56: '0xD7187746E03F9DB2E422046328161AE0cF9C439f',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.145',
    isFinished: false,
    isLpPool: true,
    chainId: 56,
  },

  {
    sousId: 15,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].viva,
    contractAddress: {
      97: '',
      56: '0x61201b20670672D01c19e4127c90412238FD3eD5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '92592',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 16,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].viva,
    earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
    contractAddress: {
      97: '',
      56: '0x70E64A27CeaD16a05e322E36b7347ebd6883C30F',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0015',
    isFinished: false,
    chainId: 56,
  },

  {
    sousId: 12,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].famy,
    contractAddress: {
      97: '',
      56: '0x98959C6CAA879571e8A70bA18a94B392f11CFBcF',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.52',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 13,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].famy,
    earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
    contractAddress: {
      97: '',
      56: '0x72FE198E6593EAdE248d4CC6Fcff00F9f06ED1f0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00146',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 14,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].famy,
    earningToken: serializedTokens[SupportedChainId.MAINNET].famy,
    contractAddress: {
      97: '',
      56: '0x18ec551238d5c38CdA04fEfe7E8A2ad5EA688183',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.292',
    isFinished: false,
    chainId: 56,
  },

  {
    sousId: 7,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].ryiu,
    earningToken: serializedTokens[SupportedChainId.MAINNET].swyftt,
    contractAddress: {
      97: '',
      56: '0xFB10a0c4e7B072342C89d635237ec89b0fc67ae1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.578',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 8,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].swyftt,
    earningToken: serializedTokens[SupportedChainId.MAINNET].swyftt,
    contractAddress: {
      97: '',
      56: '0xb340c3548ccF7DbF1c3F0c67Fad043994c95848D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.1757',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 9,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].swyftt,
    contractAddress: {
      97: '',
      56: '0xeD26bF33E08d930DEeF87cEcB10992fA3DD50bf3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.157',
    isFinished: false,
    chainId: 56,
  },

  // {
  //   sousId: 10,
  //   stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
  //   earningToken: serializedTokens[SupportedChainId.MAINNET].viva,
  //   contractAddress: {
  //     97: '',
  //     56: '0xE944C0A5064f5901fEa26792756b235603fa7de6',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '108489',
  //   isFinished: false,
  // },
  // {
  //   sousId: 11,
  //   stakingToken: serializedTokens[SupportedChainId.MAINNET].viva,
  //   earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
  //   contractAddress: {
  //     97: '',
  //     56: '0x74f95Eb23e32C2A08D6Cc6cD823eBB9567982e25',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '0.0015',
  //   isFinished: false,
  // },

  //
  {
    sousId: 4,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].bptoken,
    earningToken: serializedTokens[SupportedChainId.MAINNET].bptoken,
    contractAddress: {
      97: '',
      56: '0x057E6825FEE7ADd5E3c8D9C6523bA74C257693E4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0106',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 5,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].bptoken,
    contractAddress: {
      97: '',
      56: '0x696d62e94B38b0D3C555fF70D76Cb48912d86008',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0106',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 6,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].hegg,
    contractAddress: {
      97: '',
      56: '0xD76B984684cDd9a769441834690de78FBE988b7e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.027',
    isFinished: false,
    chainId: 56,
  },

  //
  {
    sousId: 3,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].hummingbird,
    contractAddress: {
      97: '',
      56: '0xD26F5fBf2Fb803d88F9f4730e9eb76A6b76ad84C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '128600823',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].uv,
    earningToken: serializedTokens[SupportedChainId.MAINNET].ryiu,
    contractAddress: {
      97: '',
      56: '0x81CDC97199dC671f24C41307a80d133AE1C8614a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 10,
    tokenPerBlock: '0.1921',
    isFinished: false,
    chainId: 56,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].ryiu,
    earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
    contractAddress: {
      97: '',
      56: '0xE413Bb136134D3BA8F7F6821Aa8e30108328c087',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 10,
    tokenPerBlock: '0.0139',
    isFinished: false,
    chainId: 56,
  },
  /*
  {
    sousId: 142,
    stakingToken: serializedTokens[SupportedChainId.MAINNET].doge,
    earningToken: serializedTokens[SupportedChainId.MAINNET].uv,
    contractAddress: {
      97: '',
      56: '0xbebd44824631b55991fa5f2bf5c7a4ec96ff805b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.01388',
    isFinished: false,
  },
  */
]

export default pools
