import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserNftStakedBalance, updateUserNftBalance } from 'state/actions'
import { useNftRewardPoolContract, useNftStakingVaultContract } from 'hooks/useContract'
import { fetchNftPoolsPublicDataAsync, updateNftPoolsUserData, updateUserNftRegistration } from 'state/nftStaking'
import useSelectedChain from 'hooks/useSelectedChain'
import getGasPrice from 'utils/getGasPrice'
import BigNumber from 'bignumber.js'
import { getGasLimit } from '../helpers'

const sousStake = async (chainId, stakingContract, tokenIds, value) => {
  const gasPrice = getGasPrice(chainId)
  const gasLimit = getGasLimit(
    await stakingContract.estimateGas.batchStake(tokenIds, {
      value: value?.toJSON(),
    }),
    1.08,
    200000,
  )
  const tx = await stakingContract.batchStake(tokenIds, {
    value: value?.toJSON(),
    gasPrice,
    gasLimit,
  })
  const receipt = await tx.wait()
  return receipt.status
}

const sousStakeV2 = async (chainId, stakingContract, tokenIds, value) => {
  const gasPrice = getGasPrice(chainId)
  const gasLimit = getGasLimit(
    await stakingContract.estimateGas.deposit(tokenIds, {
      value: value?.toJSON(),
    }),
    1.08,
    200000,
  )
  const tx = await stakingContract.deposit(tokenIds, {
    value: value?.toJSON(),
    gasPrice,
    gasLimit,
  })
  const receipt = await tx.wait()
  return receipt.status
}

const useNftStakePool = (sousId: number, mode: 'duration' | 'rewardPerBlock') => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const { chainId } = useSelectedChain()
  const stakingContract = useNftStakingVaultContract(sousId)
  const rewardContract = useNftRewardPoolContract(sousId)

  const handleStake = useCallback(
    async (tokenIds: number[], value?: BigNumber) => {
      if (mode === 'duration') await sousStake(chainId, stakingContract, tokenIds, value)
      else await sousStakeV2(chainId, rewardContract, tokenIds, value)
      const blockNumber = await stakingContract.provider.getBlockNumber()
      dispatch(fetchNftPoolsPublicDataAsync(chainId, blockNumber))
      dispatch(updateUserNftRegistration(sousId, account, chainId))
      dispatch(updateUserNftStakedBalance(sousId, account, chainId))
      dispatch(updateUserNftBalance(sousId, account, chainId))
    },
    [stakingContract, rewardContract, mode, dispatch, sousId, account, chainId],
  )

  return { onStake: handleStake }
}

export default useNftStakePool
