import { SUPPORTED_CHAINS, SupportedChainId } from 'config/constants/chains'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address, chainId?: number): string => {
  const selectChainId = chainId || parseInt(process.env.REACT_APP_CHAIN_ID, 0)
  return address[selectChainId] ? address[selectChainId] : address[SupportedChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = (chainId: number) => {
  return SUPPORTED_CHAINS[chainId].multicall
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getUnityDefiProfileAddress = () => {
  return getAddress(addresses.unitydefiProfile)
}
export const getUnityREVUAddress = () => {
  return getAddress(addresses.unityREVU)
}
export const getRevuFactoryAddress = () => {
  return getAddress(addresses.revuFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getUVVaultAddress = () => {
  return getAddress(addresses.uvVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialUVVaultAddress = () => {
  return getAddress(addresses.bunnySpecialUVVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getRevuSpecialV1Address = () => {
  return getAddress(addresses.revuSpecialV1)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getReferralAddress = () => {
  return getAddress(addresses.referral)
}
