import BigNumber from 'bignumber.js'
import { INftTokenInfo } from 'config/constants/types'
import { DeserializedNftPool, SerializedNftPool, SerializedNftTokenInfo } from 'state/types'
import { deserializeNftCollection, deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'

type UserData =
  | DeserializedNftPool['userData']
  | {
      isApproved: boolean
      isRegistered: boolean
      availableBalance: number | string
      stakedBalance: number | string
      claimedReward: number | string
      pendingReward: number | string
    }

export const transformUserData = (userData: UserData) => {
  return {
    isApproved: !!(userData && userData.isApproved),
    isRegistered: !!(userData && userData.isRegistered),
    availableBalance: userData ? new BigNumber(userData.availableBalance).toNumber() : 0,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance).toNumber() : 0,
    claimedReward: userData ? new BigNumber(userData.claimedReward) : BIG_ZERO,
    pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
  }
}

export const transformNftPool = (pool: SerializedNftPool): DeserializedNftPool => {
  const {
    totalStaked,
    maxTokensStaked,
    maxTokensPerUser,
    userData,
    collection,
    earningToken,
    rewards,
    requiresRegistration,
    registrationFee,
    rewardPerBlock,
    ...rest
  } = pool

  return {
    ...rest,
    requiresRegistration,
    registrationFee: new BigNumber(registrationFee),
    rewardPerBlock: new BigNumber(rewardPerBlock),
    collection: deserializeNftCollection(collection),
    earningToken: deserializeToken(earningToken),
    userData: transformUserData(userData),
    totalStaked: new BigNumber(totalStaked),
    maxTokensStaked: new BigNumber(maxTokensStaked).toNumber(),
    maxTokensPerUser: new BigNumber(maxTokensPerUser).toNumber(),
    rewards: rewards?.map((p) => ({
      duration: p.duration,
      reward: new BigNumber(p.reward),
    })),
  }
}

export const transformNftToken = (serializedToken: SerializedNftTokenInfo): INftTokenInfo => {
  const { token, stakingInfo, ...rest } = serializedToken
  const { poolId, rewards, claimed, depositDate, unlockDate, nextRewardDate } = stakingInfo || {}

  return {
    ...rest,
    token,
    stakingInfo: stakingInfo
      ? {
          poolId,
          rewards: new BigNumber(rewards),
          claimed: new BigNumber(claimed),
          depositDate: new Date(Number(depositDate) * 1000),
          unlockDate: new Date(Number(unlockDate) * 1000),
          nextRewardDate: new Date(Number(nextRewardDate) * 1000),
        }
      : undefined,
  }
}
