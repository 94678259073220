import { initializeConnector } from '@web3-react/core'
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { SupportedChainId } from 'config/constants/chains'

export const [walletConnect, hooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!,
        chains: [SupportedChainId.MAINNET],
        optionalChains: [SupportedChainId.PULSECHAIN, SupportedChainId.BITGERT],
        showQrModal: true,
        qrModalOptions: { themeVariables: { '--wcm-z-index': '100000' } } as any,
      },
    }),
)
