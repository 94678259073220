import React from 'react'
import { Svg, SvgProps } from '@unitydefi/uikit'

const SelectedIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 29 29" {...props}>
      <circle cx="14.5" cy="14.5" r="14.5" fill="black" />
      <line x1="10.1213" y1="15.3959" x2="12.8021" y2="18.0766" stroke="white" strokeWidth="3" strokeLinecap="round" />
      <line x1="12.8022" y1="18.0766" x2="18.8788" y2="12" stroke="white" strokeWidth="3" strokeLinecap="round" />
    </Svg>
  )
}

export default SelectedIcon
