import { SupportedChainId } from 'config/constants/chains'
import { serializeNftCollection } from 'state/user/hooks/helpers'
import { NftCollection, SerializedNftCollection } from './types'

const { MAINNET, TESTNET } = SupportedChainId

interface NftCollectionList {
  [symbol: string]: NftCollection
}

interface SerializedNftCollectionList {
  [symbol: string]: SerializedNftCollection
}

export const mainnetCollections = {
  revuWarriors: new NftCollection(
    MAINNET,
    '0x7fE7c8c961D000aD1E05186Fc43382DB815428c8',
    'REVU Warriors',
    'https://www.unitydefi.com/',
    'https://api.thegraph.com/subgraphs/name/unitydefi/revu-warriors',
    'https://static-nft.unitydefi.com/mainnet/0x7fe7c8c961d000ad1e05186fc43382db815428c8/{id}.png',
  ),
  // collection2: new NftCollection(
  //   MAINNET,
  //   '',
  //   'REVU Warriors 2',
  //   'https://www.unitydefi.com/',
  //   'https://api.thegraph.com/subgraphs/name/unitydefi/revu-warriors',
  //   'https://cloudflare-ipfs.com/ipfs/QmVugXu2iwzKpWPoJUtbefibSnwQdABHPxSUzQ236GvNBJ/{id}.png',
  // ),
}

export const testnetCollections = {
  revuWarriors: new NftCollection(
    TESTNET,
    '0x821B320FEC4Ef1D9Ce348d8e6d8ad6c7056e0Ca0',
    'REVU Warriors',
    'https://www.unitydefi.com/',
    'https://api.thegraph.com/subgraphs/name/unitydefi/revu-warriors',
    'https://static-nft.unitydefi.com/mainnet/0x7fe7c8c961d000ad1e05186fc43382db815428c8/{id}.png',
  ),
  // collection2: new NftCollection(
  //   TESTNET,
  //   '0x09bF3315F6e619721eCdF75f033268DC4B2f6EE9',
  //   'REVU Warriors 2',
  //   'https://www.unitydefi.com/',
  //   'https://api.thegraph.com/subgraphs/name/unitydefi/revu-warriors',
  //   'https://cloudflare-ipfs.com/ipfs/QmQfoEsje9uGW2XYBLYkXcFFPqqn5XorLDvJN5c1P72taE/{id}.png',
  // ),
}

const collections = (): NftCollectionList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === SupportedChainId.TESTNET) {
    return Object.keys(mainnetCollections).reduce((accum, key) => {
      return { ...accum, [key]: testnetCollections[key] || mainnetCollections[key] }
    }, {})
  }

  return mainnetCollections
}

export const serializeNftCollections = (): SerializedNftCollectionList => {
  const unserializedTokens = collections()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeNftCollection(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default collections()
