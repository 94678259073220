import { Currency, CurrencyAmount, Token, TokenAmount } from '@pancakeswap/sdk'
import { ETHER_TOKENS, SupportedChainId, WETH_TOKENS } from 'config/constants/chains'

export function wrappedCurrency(
  currency: Currency | undefined,
  chainId: SupportedChainId | undefined,
): Token | undefined {
  return chainId && currency === ETHER_TOKENS[chainId]
    ? WETH_TOKENS[chainId]
    : currency instanceof Token
    ? currency
    : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: SupportedChainId | undefined,
): TokenAmount | undefined {
  const token = currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : undefined
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WETH_TOKENS[token.chainId as number])) return ETHER_TOKENS[token.chainId]
  return token
}
