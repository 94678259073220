import { Token } from '@pancakeswap/sdk'
import { NftCollection, SerializedToken, SerializedNftCollection } from 'config/constants/types'
import { parseUnits } from 'ethers/lib/utils'

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
    projectLink: token.projectLink,
    imageUrl: (token as any).imageUrl,
  } as any
}

export function deserializeToken(serializedToken: SerializedToken): Token {
  const token = new Token(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name,
    serializedToken.projectLink,
  )
  ;(token as any).imageUrl = serializedToken.imageUrl
  return token
}

export function serializeNftCollection(collection: NftCollection): SerializedNftCollection {
  return {
    chainId: collection.chainId,
    address: collection.address,
    name: collection.name,
    projectLink: collection.projectLink,
    subgraphUrl: collection.subgraphUrl,
    ipfsPath: collection.ipfsPath,
  }
}

export function deserializeNftCollection(serializedNftCollection: SerializedNftCollection): NftCollection {
  return new NftCollection(
    serializedNftCollection.chainId,
    serializedNftCollection.address,
    serializedNftCollection.name,
    serializedNftCollection.projectLink,
    serializedNftCollection.subgraphUrl,
    serializedNftCollection.ipfsPath,
  )
}
