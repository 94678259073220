import React, { FC, HTMLAttributes, useMemo } from 'react'
import { INftTokenInfo } from 'config/constants/types'
import { DeserializedNftPool } from 'state/types'
import { BIG_ZERO } from 'utils/bigNumber'
import { getCurrentDailyRewardBN } from 'views/NftStaking/helpers'
import styled from 'styled-components'
import { Box, useTooltip } from '@unitydefi/uikit'
import { getBalanceNumber } from 'utils/formatBalance'
import SelectedIcon from './SelectedIcon'

export type NftListItemProps = HTMLAttributes<any> & {
  token: INftTokenInfo
  isSelected: boolean
  pool?: DeserializedNftPool
}

const ProgressContainer = styled(Box)`
  text-align: center;
  font-size: 0.75rem;
`

const NftListItem: FC<NftListItemProps> = ({ token, isSelected, pool, className, ...props }) => {
  const hasNextRewards = useMemo(
    () => !token.stakingInfo?.nextRewardDate || +token.stakingInfo?.nextRewardDate > +new Date(),
    [token],
  )

  const cls = useMemo(
    () =>
      [
        'nft-list-item',
        isSelected ? 'selected' : '',
        className || '',
        token.stakingInfo && !hasNextRewards ? 'complete' : '',
        token.stakingInfo && hasNextRewards ? 'uncomplete' : '',
      ]
        .filter((p) => p)
        .join(' '),
    [className, hasNextRewards, isSelected, token.stakingInfo],
  )

  // useEffect(() => {
  //   console.log(`token #${token.token.tokenId}, isSelected: ${isSelected}`)
  // }, [token.token.tokenId, isSelected])

  const currentDay = useMemo(() => {
    return token.stakingInfo
      ? Math.ceil((+new Date() - +token.stakingInfo.depositDate) / (24 * 60 * 60 * 1000))
      : undefined
  }, [token])

  const totalDays = useMemo(() => {
    if (!pool.rewards) return 0
    return Math.floor(pool.rewards[pool.rewards.length - 1].duration / (24 * 60 * 60))

    // return token.stakingInfo
    //   ? Math.floor((+token.stakingInfo.nextRewardDate - +token.stakingInfo.depositDate) / (24 * 60 * 60 * 1000))
    //   : undefined
  }, [pool.rewards])

  const dailyRewards = useMemo(() => {
    return token.stakingInfo && pool ? getCurrentDailyRewardBN(pool, token.stakingInfo) : BIG_ZERO
  }, [token, pool])

  const tooltipContent =
    pool?.mode === 'duration' && token?.stakingInfo ? (
      <>
        Deposit date: {token.stakingInfo.depositDate.toLocaleDateString()}
        {token.stakingInfo.rewards.minus(token.stakingInfo.claimed).gt(0) && (
          <>
            <br />
            Pending rewards:
            {getBalanceNumber(
              token.stakingInfo.rewards.minus(token.stakingInfo.claimed),
              pool.earningToken.decimals,
            )}{' '}
            {pool.earningToken.symbol}
          </>
        )}
        {token.stakingInfo.claimed.gt(0) && (
          <>
            <br />
            Claimed rewards: {getBalanceNumber(token.stakingInfo.claimed, pool.earningToken.decimals)}{' '}
            {pool.earningToken.symbol}
          </>
        )}
        {dailyRewards.gt(0) && (
          <>
            <br />
            Daily rewards: {getBalanceNumber(dailyRewards, pool.earningToken.decimals)} {pool.earningToken.symbol}
          </>
        )}
        {hasNextRewards ? (
          <>
            <br />
            Next rewards on {token.stakingInfo.nextRewardDate.toLocaleDateString()}
          </>
        ) : (
          <>
            <br />
            Staking complete!
          </>
        )}
      </>
    ) : undefined

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'right-start' })

  return (
    <>
      <div className="nft-item-container" ref={targetRef}>
        <div className={cls} {...props}>
          <img src={token.token.imageUrl} alt={`#${token.token.tokenId}`} />
          {isSelected && (
            <div className="nft-selected">
              <SelectedIcon
                style={{
                  height: '29px',
                  width: '29px',
                }}
                viewBox="0 0 29 29"
              />
            </div>
          )}
          <div className="nft-id">#{token.token.tokenId}</div>
        </div>
        {pool?.mode === 'duration' && token?.stakingInfo && (
          <ProgressContainer>
            Day {currentDay} of {totalDays}
          </ProgressContainer>
        )}
        {tooltipVisible && tooltipContent && tooltip}
      </div>
    </>
  )
}

export default NftListItem
