import { FooterLinkType } from '@unitydefi/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: 'Unitynews',
        href: 'https://unitynews.net/',
      },
      // {
      //   label: 'UV Website',
      //   href: 'https://unity-ventures.com/',
      // },
      {
        label: 'UV Token',
        href: 'https://bscscan.com/token/0xb3a95bdbe4ac65b0628db1e6600f71ed59b89255',
      },
      {
        label: 'Terms of service',
        href: 'https://docs.google.com/document/d/1EaIclaCMqoX5ozXuzMz5YHEoxDc5Wm28UUs7c9ewPeM/edit?usp=sharing',
      },
      {
        label: t('Customer Support'),
        href: 'mailto:support@ryi-unity.com',
      },
      {
        label: 'Whitepaper',
        href: 'https://ryi-unity.gitbook.io/ryi-unity-gitbook/',
      },
    ],
  },
  {
    label: t('More'),
    items: [
      {
        label: 'RYI Unity',
        href: 'https://ryi-unity.com/',
      },
      {
        label: t('RYI Governance'),
        href: 'https://www.xdao.app/56/dao/0x0da1225fdb7f944d8e67b1aec67c6c290af60719',
      },
      {
        label: 'RYIPAY (Android)',
        href: 'https://play.google.com/store/apps/details?id=io.ryi.pay',
      },
      {
        label: 'RYIPAY (iOS)',
        href: 'https://apps.apple.com/us/app/ryipay-wallet/id1620995066',
      },
      // {
      //   label: 'Merch',
      //   href: 'https://www.unity-swag.com/',
      // },
      {
        label: 'Create your staking pool',
        href: 'https://createpool.unitydefi.com',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/unitydefi',
      },
      {
        label: 'Audit',
        href: 'https://sourcehat.com/audits/UnityVentures/',
      },
    ],
  },
]
