import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useModal } from '@unitydefi/uikit'
import { useWeb3React } from '@web3-react/core'
import NftGiveawayModal from 'views/Collectibles/components/NftGiveawayModal'
import { NftAchievment } from 'config/constants/types'
import nfts from 'config/constants/achievements'
import useRevuSpecialV1 from 'views/Collectibles/hooks/useRevuSpecialV1'

interface GlobalCheckClaimStatusProps {
  excludeLocations: string[]
}

/**
 * This is represented as a component rather than a hook because we need to keep it
 * inside the Router.
 *
 * TODO: Put global checks in redux or make a generic area to house global checks
 */
const GlobalCheckClaimStatus: React.FC<GlobalCheckClaimStatusProps> = ({ excludeLocations }) => {
  const hasDisplayedModal = useRef(false)
  const [claimableNfts, setClaimableNfts] = useState<NftAchievment[]>([])
  const [onPresentGiftModal] = useModal(<NftGiveawayModal nfts={claimableNfts} />)
  const { account } = useWeb3React()
  const { pathname } = useLocation()
  const { canClaimRYIUNft, canClaimWatcheriumNft } = useRevuSpecialV1()

  // Check claim status
  useEffect(() => {
    const fetchClaimStatus = async () => {
      const claimable: NftAchievment[] = []

      const nftConfigMap = {
        ryiu: nfts.find((nft) => nft.identifier === 'ryi-unity'),
        watcherium: nfts.find((nft) => nft.identifier === 'watcherium'),
      }

      const { canClaim: isRYIUClaimable } = await canClaimRYIUNft()
      const { canClaim: isWatcheriumClaimable } = await canClaimWatcheriumNft()

      if (isRYIUClaimable) {
        claimable.push(nftConfigMap.ryiu)
      }

      if (isWatcheriumClaimable) {
        claimable.push(nftConfigMap.watcherium)
      }

      setClaimableNfts(claimable)
    }

    if (account) {
      fetchClaimStatus()
    }
  }, [account, canClaimRYIUNft, canClaimWatcheriumNft])

  // Check if we need to display the modal
  useEffect(() => {
    const matchesSomeLocations = excludeLocations.some((location) => pathname.includes(location))

    if (claimableNfts?.length && !matchesSomeLocations && !hasDisplayedModal.current) {
      onPresentGiftModal()
      hasDisplayedModal.current = true
    }
  }, [pathname, excludeLocations, hasDisplayedModal, onPresentGiftModal, claimableNfts])

  // Reset the check flag when account changes
  useEffect(() => {
    hasDisplayedModal.current = false
  }, [account, hasDisplayedModal])

  return null
}

export default GlobalCheckClaimStatus
