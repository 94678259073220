import { useEffect, useState, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { getRpcProvider } from 'lib/entities/providers'
import useSelectedChain from './useSelectedChain'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  const { connector, chainId: walletChainId, hooks, ...web3React } = useWeb3React()
  const { chainId: selectedChainId } = useSelectedChain()
  const { useSelectedProvider } = hooks
  const library = useSelectedProvider(connector)

  const chainId = walletChainId ?? selectedChainId
  const rpcProvider = getRpcProvider(chainId)
  const refEth = useRef(library)
  const [provider, setProvider] = useState(library || rpcProvider)

  useEffect(() => {
    if (library !== refEth.current) {
      // console.log(`set library`, library)
      setProvider(library || rpcProvider)
      refEth.current = library
    }
  }, [library, rpcProvider])

  useEffect(() => {
    if (!refEth.current && provider !== rpcProvider) {
      // console.log(`force set provider`, rpcProvider)
      setProvider(rpcProvider)
    }
  }, [provider, rpcProvider])

  return {
    connector,
    library: provider,
    chainId: walletChainId || selectedChainId,
    hooks,
    ...web3React,
  }
}

export default useActiveWeb3React
