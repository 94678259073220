import store from 'state'

/**
 * Function to return gasPrice outwith a react component
 */
const getGasPrice = (chainId: number): string => {
  // const chainId = process.env.REACT_APP_CHAIN_ID
  const state = store.getState()
  return state.user.gasPrice[chainId]
}

export default getGasPrice
