import { BigintIsh, ChainId, Currency, CurrencyAmount, Token } from '@pancakeswap/sdk'
import { parseUnits } from 'ethers/lib/utils'

export type SupportedChainConfiguration = {
  chainId: number
  isDefault: boolean
  key: string
  name: string
  rpcUrl: string
  multicall: string
  currencyName: string
  currencySymbol: string
  factoryAddress: string
  routerAddress: string
  initCodeHash: string
  explorerName: string
  explorerUrl: string
  gasPrices?: {
    default: string
    fast: string
    instant: string
  }
  gasPricesGwei?: {
    default: string
    fast: string
    instant: string
  }
}
export enum SupportedChainId {
  MAINNET = ChainId.MAINNET,
  TESTNET = ChainId.TESTNET,
  PULSECHAIN = 369,
  BASE = 8453,
  BITGERT = 32520,
  PHX = 13381,
}

export const SUPPORTED_CHAINS: { [key in SupportedChainId]?: SupportedChainConfiguration } = {
  [SupportedChainId.MAINNET]: {
    chainId: SupportedChainId.MAINNET,
    key: 'bsc',
    isDefault: true,
    name: 'Binance Smart Chain',
    rpcUrl: 'https://bsc-dataseed1.ninicoin.io',
    multicall: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    currencyName: 'BNB',
    currencySymbol: 'BNB',
    factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
    routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    initCodeHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
    explorerName: 'BscScan',
    explorerUrl: 'https://bscscan.com',
    gasPrices: {
      default: '3',
      fast: '5',
      instant: '7',
    },
    gasPricesGwei: {
      default: parseUnits('3', 'gwei').toString(),
      fast: parseUnits('5', 'gwei').toString(),
      instant: parseUnits('7', 'gwei').toString(),
    },
  },
  [SupportedChainId.BITGERT]: {
    chainId: SupportedChainId.BITGERT,
    isDefault: false,
    name: 'Bitgert',
    key: 'bitgert',
    rpcUrl: 'https://rpc.icecreamswap.com',
    multicall: '0x2490b172F7de4f518713fB03E6D3f57B558c9964',
    currencyName: 'Brise',
    currencySymbol: 'Brise',
    factoryAddress: '0x9E6d21E759A7A288b80eef94E4737D313D31c13f',
    routerAddress: '0xBb5e1777A331ED93E07cF043363e48d320eb96c4',
    initCodeHash: '0x58c1b429d0ffdb4407396ae8118c58fed54898473076d0394163ea2198f7c4a3',
    explorerName: 'BriseScan',
    explorerUrl: 'https://brisescan.com',
  },
  [SupportedChainId.PULSECHAIN]: {
    chainId: SupportedChainId.PULSECHAIN,
    isDefault: false,
    name: 'Pulse Chain',
    key: 'pulsechain',
    rpcUrl: 'https://rpc.pulsechain.com',
    multicall: '0x3Ef41816ed8d4Bd25888E96eea059a516538AE3B',
    currencyName: 'PLS',
    currencySymbol: 'PLS',
    factoryAddress: '0x1715a3E4A142d8b698131108995174F37aEBA10D',
    routerAddress: '0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02',
    initCodeHash: '0x59fffffddd756cba9095128e53f3291a6ba38b21e3df744936e7289326555d62',
    explorerName: 'PulseChain Explorer',
    explorerUrl: 'https://scan.pulsechain.com',
  },
  [SupportedChainId.BASE]: {
    chainId: SupportedChainId.BASE,
    isDefault: false,
    name: 'Base',
    key: 'base',
    rpcUrl: 'https://mainnet.base.org',
    multicall: '0x22cdc93F53Ee3F6b8Ad66faD6f98915a5349950E',
    currencyName: 'ETH',
    currencySymbol: 'ETH',
    factoryAddress: '0x1B8128c3A1B7D20053D10763ff02466ca7FF99FC',
    routerAddress: '0x4cf76043B3f97ba06917cBd90F9e3A2AAC1B306e',
    initCodeHash: '0x32d4b730a0e562de3cbe5b62b68f3312e55fab8d511c97e70928147f673be977',
    explorerName: 'BaseScan',
    explorerUrl: 'https://basescan.org',
  },
  [SupportedChainId.PHX]: {
    chainId: SupportedChainId.PHX,
    isDefault: false,
    name: 'Phoenix',
    key: 'phoenix',
    rpcUrl: 'https://rpc.phoenixplorer.com',
    multicall: '0x2F225295d04770Be3891A7fE467B2d7FB690104b',
    currencyName: 'PHX',
    currencySymbol: 'PHX',
    factoryAddress: '0xaeA039F542c88Cc14A6Ca38deeeECf91D9B790D6',
    routerAddress: '0x8d5567953B0aC3348C959c722D4327f29155AEE4',
    initCodeHash: '0x7ed09a7b84ea0be1e78dda78cec9a047927b5deb096b515cd64337bb726fea80',
    explorerName: 'Phoenixplorer',
    explorerUrl: 'https://phoenixplorer.com',
  },
}

export const SUPPORTED_CHAINS_LIST = Object.values(SUPPORTED_CHAINS)

export class Currency_ extends Currency {
  // eslint-disable-next-line no-useless-constructor
  constructor(decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
  }
}

export class CurrencyAmount_ extends CurrencyAmount {
  // eslint-disable-next-line no-useless-constructor
  constructor(currency: Currency, amount: BigintIsh) {
    super(currency, amount)
  }

  static etherMulti(chainId: number, amount: BigintIsh): CurrencyAmount {
    return new CurrencyAmount_(ETHER_TOKENS[chainId], amount)
  }
}
export const ETHER_TOKENS: { [key in SupportedChainId]: Currency } = {
  [SupportedChainId.MAINNET]: Currency.ETHER,
  [SupportedChainId.TESTNET]: Currency.ETHER,
  [SupportedChainId.BITGERT]: new Currency_(18, 'Brise', 'Brise'),
  [SupportedChainId.PULSECHAIN]: new Currency_(18, 'PLS', 'PLS'),
  [SupportedChainId.BASE]: new Currency_(18, 'ETH', 'ETH'),
  [SupportedChainId.PHX]: new Currency_(18, 'PHX', 'PHX'),
}
export const WETH_TOKENS: { [key in SupportedChainId]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET as number,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
  ),
  [SupportedChainId.TESTNET]: new Token(
    SupportedChainId.TESTNET as number,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
  ),
  [SupportedChainId.BITGERT]: new Token(
    SupportedChainId.BITGERT as number,
    '0x0eb9036cbE0f052386f36170c6b07eF0a0E3f710',
    18,
    'WBRISE',
    'Wrapped BRISE',
  ),
  [SupportedChainId.PULSECHAIN]: new Token(
    SupportedChainId.PULSECHAIN as number,
    '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    18,
    'WPLS',
    'Wrapped Pulse',
  ),
  [SupportedChainId.BASE]: new Token(
    SupportedChainId.BASE as number,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped ETH',
  ),
  [SupportedChainId.PHX]: new Token(
    SupportedChainId.PHX as number,
    '0xcb186051DD62914B6dFc56c257823bfDA20DbEe6',
    18,
    'WPHX',
    'Wrapped PHX',
  ),
}
