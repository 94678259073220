export default {
  masterChef: {
    97: '',
    56: '0x2eE006bc0562E5b0ef37C93dCaFA464706f628F7',
  },
  sousChef: {
    97: '',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  referral: {
    56: '0x047AF9424a94AF5f060c8E70361CF4403FEf0344',
    97: '',
  },
  lotteryV2: {
    97: '',
    56: '0x4B877b7693717a900811453659ff48757e1b3394',
  },
  // multiCall: {
  //   56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  //   97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  //   32520: '0x2490b172F7de4f518713fB03E6D3f57B558c9964',
  // },
  unitydefiProfile: {
    56: '0x992bB887b75d9de0882641acED3dda7401bdD8B4', // '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '',
  },
  unityREVU: {
    56: '0xa15A9a4d3cacFD897C49B400e0Dda37d43eA0629', // '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '',
  },
  revuFactory: {
    56: '0xdb8314566324fA86550799810413663cE2B29Da2', // '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '',
  },
  claimRefund: {
    56: '', // '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '', // '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '',
  },
  bunnySpecial: {
    56: '', // '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '',
  },
  tradingCompetition: {
    56: '', // '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '',
  },
  easterNft: {
    56: '', // '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '',
  },
  uvVault: {
    56: '0x2cdd9325974D88e5C7E522c25164CA44cC321612',
    97: '',
  },
  predictions: {
    56: '', // '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '', // '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialUVVault: {
    56: '', // '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '', // '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '', // '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '',
  },
  revuSpecialV1: {
    56: '0xc52Ac330a72cb9D7697E5383aee114dc340dD621', // '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '',
  },
  farmAuction: {
    56: '', // '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0xE33cB1A7D51e9e84724615cF48778d2C07079D67', // 0x17539cCa21C7933Df5c980172d22659B8C345C5A
    97: '',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
}
