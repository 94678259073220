import { useContext } from 'react'
import { SelectedChainContext } from 'contexts/SelectedChainContext'

export default function useSelectedChain() {
  const { selectedChain, setSelectedChain } = useContext(SelectedChainContext)
  return {
    selectedChain,
    setSelectedChain,
    chainId: selectedChain.chainId,
  }
}
