import nftStakingVaultABI from 'config/abi/NftStakingVault.json'
import nftRewardPoolABI from 'config/abi/NftRewardPool.json'
import erc721ABI from 'config/abi/IERC721.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { nftPoolsConfig } from 'config/constants'
import { getErc721CollectionContract, getNftStakingVaultContract } from 'utils/contractHelpers'
import { INftToken, SerializedNftPoolConfig } from 'config/constants/types'
import { constants } from 'ethers'
import { SerializedNftTokenInfo } from 'state/types'
import request, { gql } from 'graphql-request'

export const fetchNftPoolsAllowance = async (account, chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const calls = chainPools.map((pool) => ({
    address: pool.collection.address,
    name: 'isApprovedForAll',
    params: [account, getAddress(pool.contractAddress, chainId)],
  }))

  const allowances = (await multicall(chainId, erc721ABI, calls)).map((p) => (p instanceof Array ? p[0] : p))
  return chainPools.reduce((acc, pool, index) => ({ ...acc, [pool.sousId]: allowances[index] }), {})
}

export const fetchNftPoolsRegistrations = async (account, chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const calls = chainPools.map((pool) => ({
    address: getAddress(pool.contractAddress, chainId),
    name: 'isRegistered',
    params: [account],
  }))

  const isRegistered = (await multicall(chainId, nftStakingVaultABI, calls)).map((p) => (p instanceof Array ? p[0] : p))
  return chainPools.reduce((acc, pool, index) => ({ ...acc, [pool.sousId]: isRegistered[index] }), {})
}

export const fetchUserNftBalances = async (account, chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const calls = chainPools.map((pool) => ({
    address: pool.collection.address,
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(chainId, erc721ABI, calls)
  const tokenBalances = chainPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )

  return { ...tokenBalances }
}

export const fetchUserNftTokens = async (account, chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const collections = chainPools
    .map((pool) => pool.collection)
    .filter((collection, idx, ar) => ar.indexOf(ar.find((x) => x.address === collection.address)) === idx)
  const enumerableCollections = collections.filter((p) => !p.subgraphUrl)
  const sgCollections = collections.filter((p) => !!p.subgraphUrl)

  const walletTokensIdsA = await Promise.all(
    enumerableCollections.map((p) => fetchUserWalletTokens(account, p.address, chainId)),
  )
  const walletTokensIdsB = await Promise.all(
    sgCollections.map((p) => fetchUserWalletTokensSubgraph(account, p.subgraphUrl)),
  )
  const stakedTokenIds = await Promise.all(chainPools.map((p) => fetchUserStakedTokens(account, p)))

  const walletTokenA = enumerableCollections.flatMap((collection, collectionIdx) =>
    walletTokensIdsA[collectionIdx].map((tokenId) =>
      fetchTokenInfo(collection.address, tokenId, 'wallet', collection.ipfsPath),
    ),
  )
  const walletTokenB = sgCollections.flatMap((collection, collectionIdx) =>
    walletTokensIdsB[collectionIdx].map((tokenId) =>
      fetchTokenInfo(collection.address, tokenId, 'wallet', collection.ipfsPath),
    ),
  )
  const stakedToken = (
    await Promise.all(
      chainPools.map((pool, poolIdx) =>
        fetchStakeInfos(
          pool,
          stakedTokenIds[poolIdx].map((tokenId) =>
            fetchTokenInfo(pool.collection.address, tokenId, 'staking', pool.collection.ipfsPath),
          ),
        ),
      ),
    )
  ).flatMap((p) => p)

  const tokens: SerializedNftTokenInfo[] = [
    ...stakedToken,
    ...[...walletTokenA, ...walletTokenB].map((token) => ({ token })),
  ].filter((p, idx, ar) => ar.indexOf(ar.find((x) => x.token.tokenId === p.token.tokenId)) === idx)

  return tokens
}

export const fetchUserWalletTokens = async (account: string, collection: string, chainId) => {
  const contract = getErc721CollectionContract(null, collection)

  const balance = await contract.balanceOf(account)
  if (balance.gt(0)) {
    const calls = [...Array(balance.toNumber())].map((_, idx) => ({
      address: collection,
      name: 'tokenOfOwnerByIndex',
      params: [account, idx],
    }))

    const tokenIds = await multicall(chainId, erc721ABI, calls)
    return tokenIds.map((p) => p.toNumber())
  }

  return []
}

export const fetchUserWalletTokensSubgraph = async (account: string, subgraphUrl: string) => {
  // creturn [...Array(50)].map((_, idx) => idx + 1)

  const tokenIds: number[] = []

  const response = await request(
    subgraphUrl,
    gql`
      query loadTokens($owner: String!) {
        owner(id: $owner) {
          id
          tokens {
            id
          }
        }
      }
    `,
    { owner: account.toLowerCase() },
  )
  tokenIds.push(...(response?.owner?.tokens || []).map((p) => parseInt(p.id)))
  return tokenIds
}

export const fetchUserStakedTokens = async (account: string, pool: SerializedNftPoolConfig) => {
  const stakingContract = getNftStakingVaultContract(pool.sousId)

  const balance = await stakingContract.getUserStakedTokensCount(account)
  const calls = [...Array(balance.toNumber())].map((_, idx) => ({
    address: stakingContract.address,
    name: 'getUserStakedTokens',
    params: [account, 1, idx],
  }))

  const tokenIds = await multicall(pool.chainId, nftStakingVaultABI, calls)
  return tokenIds.flatMap((p) => p[0].map((x) => x.toNumber()))
}

export const fetchTokenInfo = (
  collectionId: string,
  tokenId: number,
  location: 'wallet' | 'staking',
  ipfsPath?: string,
): INftToken => {
  const imageUrl = ipfsPath.replace('{id}', tokenId.toString())
  // const imageUrl = ipfsPath
  //   ? ipfsPath.replace('{id}', tokenId.toString())
  //   : `https://cloudflare-ipfs.com/ipfs/${CollectionImages[collectionId][tokenId]}`
  return {
    collection: collectionId,
    tokenId,
    location,
    imageUrl,
  }
}

export const fetchStakeInfos = async (
  pool: SerializedNftPoolConfig,
  tokens: INftToken[],
): Promise<SerializedNftTokenInfo[]> => {
  if (pool.mode === 'rewardPerBlock') {
    return tokens.map((token) => ({
      token,
      stakingInfo: {
        poolId: pool.sousId,
      },
    }))
  }

  const calls = tokens.map((token) => ({
    address: getAddress(pool.contractAddress, pool.chainId),
    name: 'getStakeInfo',
    params: [token.tokenId],
  }))

  const results = await multicall(pool.chainId, nftStakingVaultABI, calls)

  // address owner, // owner
  // uint256 depositDate, // deposit date
  // uint256 minUnlockDate, // min unlock date
  // uint256 rewards, // rewards
  // uint256 claimed, // claimed
  // uint256 nextRewardDate // next/last reward date

  return results.map((result, idx) => ({
    token: tokens[idx],
    stakingInfo:
      result && result.owner !== constants.AddressZero
        ? {
            poolId: pool.sousId,
            rewards: result.rewards.toString(),
            claimed: result.claimed.toString(),
            depositDate: result.depositDate.toString(),
            unlockDate: result.minUnlockDate.toString(),
            nextRewardDate: result.nextRewardDate.toString(),
          }
        : undefined,
  }))
}

export const fetchUserNftStakeBalances = async (account, chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const calls = chainPools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'getUserStakedTokensCount',
    params: [account],
  }))
  const balances = await multicall(chainId, nftStakingVaultABI, calls)
  const stakedBalances = chainPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(balances[index]).toJSON(),
    }),
    {},
  )

  return { ...stakedBalances }
}

const fetchUserNftPendingRewardsDuration = async (account, chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const pools = chainPools.filter((p) => p.mode === 'duration')
  const calls = pools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'getUserStakedTokensCount',
    params: [account],
  }))
  const balances = await multicall(chainId, nftStakingVaultABI, calls)
  const stakedBalances = pools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(balances[index]).toJSON(),
    }),
    {},
  )

  return { ...stakedBalances }
}

const fetchUserNftPendingRewardsPerBlock = async (account, chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const pools = chainPools.filter((p) => p.mode === 'rewardPerBlock')
  const calls = pools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'pendingReward',
    params: [account],
  }))
  const balances = await multicall(chainId, nftRewardPoolABI, calls)
  const stakedBalances = pools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(balances[index]).toJSON(),
    }),
    {},
  )

  return { ...stakedBalances }
}

export const fetchUserNftPendingRewards = async (account, chainId: number) => {
  const durations = await fetchUserNftPendingRewardsDuration(account, chainId)
  const blocks = await fetchUserNftPendingRewardsPerBlock(account, chainId)

  return { ...durations, ...blocks }
}
