import invariant from 'tiny-invariant'
import { Currency, Price, Token } from '@pancakeswap/sdk'
import { ETHER_TOKENS, WETH_TOKENS } from 'config/constants/chains'
import { Pair } from './pair'

export class Route {
  public readonly pairs: Pair[]

  public readonly path: Token[]

  public readonly input: Currency

  public readonly output: Currency

  public readonly midPrice: Price

  public constructor(pairs: Pair[], input: Currency, output?: Currency) {
    invariant(pairs.length > 0, 'PAIRS')
    const { chainId } = pairs[0]
    invariant(
      pairs.every((pair) => pair.chainId === chainId),
      'CHAIN_IDS',
    )
    invariant(
      (input instanceof Token && pairs[0].involvesToken(input)) ||
        (input === ETHER_TOKENS[chainId] && pairs[0].involvesToken(WETH_TOKENS[chainId])),
      'INPUT',
    )
    invariant(
      typeof output === 'undefined' ||
        (output instanceof Token && pairs[pairs.length - 1].involvesToken(output)) ||
        (output === ETHER_TOKENS[chainId] && pairs[pairs.length - 1].involvesToken(WETH_TOKENS[chainId])),
      'OUTPUT',
    )

    const path: Token[] = [input instanceof Token ? input : WETH_TOKENS[chainId]]
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, pair] of pairs.entries()) {
      const currentInput = path[i]
      invariant(currentInput.equals(pair.token0) || currentInput.equals(pair.token1), 'PATH')
      const output2 = currentInput.equals(pair.token0) ? pair.token1 : pair.token0
      path.push(output2)
    }

    this.pairs = pairs
    this.path = path
    this.midPrice = Route.priceFromRoute(this)
    this.input = input
    this.output = output ?? path[path.length - 1]
  }

  public static priceFromRoute(route: Route): Price {
    const prices: Price[] = []
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, pair] of route.pairs.entries()) {
      prices.push(
        route.path[i].equals(pair.token0)
          ? new Price(pair.reserve0.currency, pair.reserve1.currency, pair.reserve0.raw, pair.reserve1.raw)
          : new Price(pair.reserve1.currency, pair.reserve0.currency, pair.reserve1.raw, pair.reserve0.raw),
      )
    }
    return prices.slice(1).reduce((accumulator, currentValue) => accumulator.multiply(currentValue), prices[0])
  }

  public get chainId() {
    return this.pairs[0].chainId
  }
}
