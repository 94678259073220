import React, { useCallback, useEffect, useMemo } from 'react'
import { Flex, Text } from '@unitydefi/uikit'
import Select from 'components/Select/Select'
import { SUPPORTED_CHAINS, SUPPORTED_CHAINS_LIST } from 'config/constants/chains'
import useSelectedChain from 'hooks/useSelectedChain'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { switchWalletChain } from 'utils/wallet'
import ChainIcon from './chainIcon'

export default function ChainSelector() {
  const { chainId: walletChainId, account } = useActiveWeb3React()
  const { selectedChain, setSelectedChain } = useSelectedChain()

  const options = useMemo(
    () =>
      SUPPORTED_CHAINS_LIST.map((chain) => ({
        label: (
          <>
            <ChainIcon
              chain={chain.key}
              width={16}
              height={16}
              style={{ verticalAlign: 'middle', marginRight: '0.25rem' }}
            />
            <Text as="span" color="text">
              {chain.name}
            </Text>
          </>
        ),
        value: chain.chainId,
      })),
    [],
  )

  const selectedIndex = useMemo(() => {
    const selected = options.find((p) => p.value === selectedChain.chainId)
    if (selected) return options.indexOf(selected)
    return undefined
  }, [options, selectedChain.chainId])

  const updateChain = useCallback(
    (value: number) => {
      setSelectedChain(SUPPORTED_CHAINS[value])
    },
    [setSelectedChain],
  )

  useEffect(() => {
    // console.log({ walletChainId, account, selectedChain: selectedChain.chainId })

    async function switchNetwork() {
      // console.log(`switching to ${selectedChain.chainId}`)
      const switched = await switchWalletChain(selectedChain.chainId)
      if (!switched) {
        const walletChain = SUPPORTED_CHAINS_LIST.find((p) => p.chainId === walletChainId)
        // console.log(`switch failed! reveting to ${walletChainId}`, walletChain)
        if (walletChain) setSelectedChain(walletChain)
      }
    }

    if (account && walletChainId !== selectedChain.chainId) {
      switchNetwork()
    }
  }, [walletChainId, account, selectedChain.chainId, setSelectedChain])

  return (
    <Flex>
      <Select selectedIndex={selectedIndex} options={options} onOptionChange={(e) => updateChain(parseInt(e.value))} />
    </Flex>
  )
}
