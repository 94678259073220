import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Skeleton, Text } from '@unitydefi/uikit'
import { DeserializedNftPool } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { getTotalRewards, getTotalDuration } from 'views/NftStaking/helpers'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { BLOCKS_PER_DAY } from 'config'
import BaseCell, { CellContent } from './BaseCell'

interface RewardCellProps {
  pool: DeserializedNftPool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const RewardCell: React.FC<RewardCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { earningToken, rewards, rewardPerBlock, totalStaked } = pool

  const rewardPerBlockEarning = useMemo(() => {
    return rewardPerBlock && totalStaked.gt(0)
      ? getBalanceNumber(
          rewardPerBlock.multipliedBy(BLOCKS_PER_DAY).div(totalStaked),
          pool.earningToken.decimals,
        ).toFixed(2)
      : undefined
  }, [pool.earningToken.decimals, rewardPerBlock, totalStaked])

  // useEffect(() => {
  //   console.log({
  //     rewardPerBlock: rewardPerBlock.toString(),
  //     rewardPerBlockEarning,
  //     x: rewardPerBlock.multipliedBy(BLOCKS_PER_DAY),
  //     pool,
  //   })
  // }, [rewardPerBlock, rewardPerBlockEarning, pool])

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {pool.mode === 'rewardPerBlock' ? t('Estimated rewards per NFT') : t('Rewards per NFT')}
        </Text>
        {rewards ? (
          <Text color="text">
            {getTotalRewards(pool)} {earningToken.symbol} in {getTotalDuration(pool)} days
          </Text>
        ) : rewardPerBlockEarning ? (
          <Text color="text">
            {rewardPerBlockEarning} {earningToken.symbol} in {1} day
          </Text>
        ) : (
          <Skeleton width="80px" height="16px" />
        )}
      </CellContent>
    </StyledCell>
  )
}

export default RewardCell
