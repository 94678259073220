import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useRefresh from 'hooks/useRefresh'
import { INftTokenInfo } from 'config/constants/types'
import useCurrentChainRpcProvider from 'hooks/useCurrentChainRpcProvider'
import {
  fetchNftPoolsPublicDataAsync,
  fetchNftPoolsUserDataAsync,
  fetchNftUserTokensAsync,
  fetchNftPoolsExtensionsDataAsync,
} from '.'
import { State, DeserializedNftPool } from '../types'
import { transformNftPool, transformNftToken } from './helpers'

export const useFetchPublicNftPoolsData = (chainId: number) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const rpcProvider = useCurrentChainRpcProvider()

  useEffect(() => {
    const fetchNftPoolsPublicData = async () => {
      const blockNumber = await rpcProvider.getBlockNumber()
      dispatch(fetchNftPoolsPublicDataAsync(blockNumber, chainId))
    }

    fetchNftPoolsPublicData()
  }, [dispatch, slowRefresh, chainId, rpcProvider])
}

export const useFetchNftExtensionPools = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchPoolsExtensionsData = async () => {
      dispatch(fetchNftPoolsExtensionsDataAsync())
    }

    fetchPoolsExtensionsData()
  }, [dispatch, slowRefresh])
}

export const useFetchUserNftPools = (chainId: number, account) => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchNftPoolsUserDataAsync(chainId, account))
    }
  }, [account, dispatch, fastRefresh, chainId])
}

export const useNftPools = (): { nftPools: DeserializedNftPool[]; userDataLoaded: boolean } => {
  const { nftPools, userDataLoaded } = useSelector((state: State) => ({
    nftPools: state.nftPools.data,
    userDataLoaded: state.nftPools.userDataLoaded,
  }))
  return { nftPools: nftPools.map(transformNftPool), userDataLoaded }
}

export const useFetchUserNfts = (chainId: number, account) => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchNftUserTokensAsync(account, chainId))
    }
  }, [account, dispatch, slowRefresh, chainId])
}

export const useUserNfts = (): { nftTokens: INftTokenInfo[]; userNftLoaded: boolean } => {
  const { nftTokens, userNftLoaded } = useSelector((state: State) => ({
    nftTokens: state.nftPools.userTokens.data,
    userNftLoaded: state.nftPools.userTokens.userNftLoaded,
  }))
  return { nftTokens: nftTokens.map(transformNftToken), userNftLoaded } // : nftTokens.map(transformNftPool)
}
