import { useMemo } from 'react'
import { Token } from '@pancakeswap/sdk'
import { useSelector } from 'react-redux'
import useSelectedChain from 'hooks/useSelectedChain'
import { SupportedChainId } from 'config/constants/chains'
import { AppState } from '../../index'
import { deserializeToken } from './helpers'

export default function useUserAddedTokens(): Token[] {
  const { selectedChain } = useSelectedChain()
  const serializedTokensMap = useSelector<AppState, AppState['user']['tokens']>(({ user: { tokens } }) => tokens)

  return useMemo(() => {
    if (!selectedChain.chainId) return []
    return Object.values(serializedTokensMap?.[selectedChain.chainId as SupportedChainId] ?? {}).map(deserializeToken)
  }, [serializedTokensMap, selectedChain.chainId])
}
