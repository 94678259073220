import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import {
  Box,
  Button,
  Flex,
  HelpIcon,
  Link,
  LinkExternal,
  MetamaskIcon,
  Skeleton,
  Text,
  TimerIcon,
  useTooltip,
} from '@unitydefi/uikit'
import { BASE_BSC_SCAN_URL } from 'config'
import { getExplorerLink } from 'utils'
import { useBlock } from 'state/block/hooks'
import { DeserializedNftPool } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { getAddress } from 'utils/addressHelpers'
import { registerToken } from 'utils/wallet'
import { getNftPoolBlockInfo } from 'views/NftStaking/helpers'
import { getBalanceNumber } from 'utils/formatBalance'
import Stake from './Stake'
import ClaimAction from './Claim'

const expandAnimation = keyframes`
  from {
    max-height: 0px;
  }
  to {
    max-height: 700px;
  }
`

const collapseAnimation = keyframes`
  from {
    max-height: 700px;
  }
  to {
    max-height: 0px;
  }
`

const StyledActionPanel = styled.div<{ expanded: boolean }>`
  animation: ${({ expanded }) =>
    expanded
      ? css`
          ${expandAnimation} 300ms linear forwards
        `
      : css`
          ${collapseAnimation} 300ms linear forwards
        `};
  overflow: hidden;
  background: ${({ theme }) => theme.colors.dropdown};
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  padding: 12px;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    padding: 16px 32px;
  }
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }
`

type MediaBreakpoints = {
  isXs: boolean
  isSm: boolean
  isMd: boolean
  isLg: boolean
  isXl: boolean
  isXxl: boolean
}

interface ActionPanelProps {
  account: string
  pool: DeserializedNftPool
  userDataLoaded: boolean
  expanded: boolean
  breakpoints: MediaBreakpoints
}

const InfoSection = styled(Box)`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 8px 8px;
  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0;
    flex-basis: 230px;
  }
`
// const ActionPanel: React.FC<ActionPanelProps> = ({ account, pool, userDataLoaded, expanded, breakpoints }) => {
//   return (<>Action panel</>)
// }
const ActionPanel: React.FC<ActionPanelProps> = ({ account, pool, userDataLoaded, expanded, breakpoints }) => {
  const { sousId, collection, earningToken, totalStaked, maxTokensPerUser, maxTokensStaked, contractAddress } = pool
  const { t } = useTranslation()
  const poolContractAddress = getAddress(contractAddress)
  // const { currentBlock } = useBlock()
  const { isXs, isSm, isMd } = breakpoints
  const showSubtitle = (isXs || isSm) && sousId === 0

  // const { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay } =
  //   getNftPoolBlockInfo(pool, currentBlock)

  const isMetaMaskInScope = !!window.ethereum?.isMetaMask
  const tokenAddress = earningToken.address || ''

  // const availableBalance = userData?.availableBalance ? new BigNumber(userData.availableBalance) : BIG_ZERO
  // const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  // const poolStakingTokenBalance = stakedBalance.plus(availableBalance)

  const getTotalStakedBalance = () => {
    return totalStaked?.toNumber() || 0
  }

  const {
    targetRef: totalStakedTargetRef,
    tooltip: totalStakedTooltip,
    tooltipVisible: totalStakedTooltipVisible,
  } = useTooltip(
    t('Total %unit% of %symbol% staked in this pool', {
      symbol: collection.name,
      unit: 'NFT',
    }),
    {
      placement: 'bottom',
    },
  )

  const {
    targetRef: feeTargetRef,
    tooltip: feeTooltip,
    tooltipVisible: feeTooltipVisible,
  } = useTooltip(t('One time fee paid during your first staking'), {
    placement: 'bottom',
  })

  // const blocksRow =
  //   blocksRemaining || blocksUntilStart ? (
  //     <Flex mb="8px" justifyContent="space-between">
  //       <Text>{hasPoolStarted ? t('Ends in') : t('Starts in')}:</Text>
  //       <Flex>
  //         <Link external href={getExplorerLink(hasPoolStarted ? endBlock : startBlock, 'countdown')}>
  //           <Balance fontSize="16px" value={blocksToDisplay} decimals={0} color="primary" />
  //           <Text ml="4px" color="primary" textTransform="lowercase">
  //             {t('Blocks')}
  //           </Text>
  //           <TimerIcon ml="4px" color="primary" />
  //         </Link>
  //       </Flex>
  //     </Flex>
  //   ) : (
  //     <Skeleton width="56px" height="16px" />
  //   )

  const feeRow = pool.registrationFee ? (
    <Flex justifyContent="space-between">
      <Text>{t('Registration fee')}:</Text>
      <Flex>
        <Balance fontSize="16px" value={getBalanceNumber(pool.registrationFee, 18)} decimals={2} />
        <Text ml="4px">{t('BNB')}</Text>
        <Text>
          <span ref={feeTargetRef}>
            <HelpIcon color="textSubtle" width="20px" ml="4px" />
          </span>
        </Text>
        {feeTooltipVisible && feeTooltip}
      </Flex>
    </Flex>
  ) : (
    <Skeleton width="56px" height="16px" />
  )

  const totalStakedRow = (
    <Flex justifyContent="space-between" alignItems="center">
      <Text maxWidth={['50px', '100%']}>{t('Nft staked')}:</Text>
      <Flex alignItems="center">
        {totalStaked && totalStaked.gte(0) ? (
          <>
            <Balance fontSize="16px" value={getTotalStakedBalance()} decimals={0} unit={` NFT`} />
            <span ref={totalStakedTargetRef}>
              <HelpIcon color="textSubtle" width="20px" ml="4px" />
            </span>
          </>
        ) : (
          <Skeleton width="56px" height="16px" />
        )}
        {totalStakedTooltipVisible && totalStakedTooltip}
      </Flex>
    </Flex>
  )

  const maxTokensPerUserRow = pool.maxTokensPerUser ? (
    <Flex justifyContent="space-between">
      <Text>{t('Max stake per user')}:</Text>
      <Flex>
        <Balance fontSize="16px" value={pool.maxTokensPerUser} decimals={0} />
        <Text ml="4px">{t('NFTs')}</Text>
        {/* <Text>
          <span ref={maxTokensPerUserTargetRef}>
            <HelpIcon color="textSubtle" width="20px" ml="4px" />
          </span>
        </Text>
        {maxTokensPerUserVisible && maxTokensPerUserTooltip} */}
      </Flex>
    </Flex>
  ) : (
    <Skeleton width="56px" height="16px" />
  )

  const maxTokensStakedRow = pool.maxTokensStaked ? (
    <Flex justifyContent="space-between">
      <Text>{t('Max NFT staked')}:</Text>
      <Flex>
        <Balance fontSize="16px" value={pool.maxTokensStaked} decimals={0} />
        <Text ml="4px">{t('NFTs')}</Text>
        {/* <Text>
          <span ref={maxTokensStakedTargetRef}>
            <HelpIcon color="textSubtle" width="20px" ml="4px" />
          </span>
        </Text>
        {maxTokensStakedVisible && maxTokensStakedTooltip} */}
      </Flex>
    </Flex>
  ) : (
    <Skeleton width="56px" height="16px" />
  )

  return (
    <StyledActionPanel expanded={expanded}>
      <InfoSection>
        {(isXs || isSm || isMd) && totalStakedRow}
        {/* {shouldShowBlockCountdown && blocksRow} */}
        {pool.requiresRegistration && feeRow}
        {pool.maxTokensPerUser > 0 && maxTokensPerUserRow}
        {pool.maxTokensStaked > 0 && maxTokensStakedRow}
        {/* <Flex mb="8px" justifyContent={['flex-end', 'flex-end', 'flex-start']}>
          <LinkExternal href={`/info/token/${earningToken.address}`} bold={false}>
            {t('See Token Info')}
          </LinkExternal>
        </Flex> */}
        <Flex mt="8px" mb="8px" justifyContent={['flex-end', 'flex-end', 'flex-start']}>
          <LinkExternal href={earningToken.projectLink} bold={false}>
            {t('View Project Site')}
          </LinkExternal>
        </Flex>
        {poolContractAddress && (
          <Flex mb="8px" justifyContent={['flex-end', 'flex-end', 'flex-start']}>
            <LinkExternal href={getExplorerLink(poolContractAddress, 'address', pool.chainId)} bold={false}>
              {t('View Contract')}
            </LinkExternal>
          </Flex>
        )}
        {account && isMetaMaskInScope && tokenAddress && (
          <Flex mb="8px" justifyContent={['flex-end', 'flex-end', 'flex-start']}>
            <Button
              variant="text"
              p="0"
              height="auto"
              onClick={() => registerToken(tokenAddress, earningToken.symbol, earningToken.decimals)}
            >
              <Text color="primary">{t('Add to Metamask')}</Text>
              <MetamaskIcon ml="4px" />
            </Button>
          </Flex>
        )}
        {/* {isAutoVault ? <CompoundingPoolTag /> : <ManualPoolTag />}
        {tagTooltipVisible && tagTooltip}
        <span ref={tagTargetRef}>
          <HelpIcon ml="4px" width="20px" height="20px" color="textSubtle" />
        </span> */}
      </InfoSection>
      <ActionContainer>
        {showSubtitle && (
          <Text mt="4px" mb="16px" color="textSubtle">
            {`${t('Earn')} UV ${t('Stake').toLocaleLowerCase()} UV`}
          </Text>
        )}
        <ClaimAction pool={pool} userDataLoaded={userDataLoaded} />
        <Stake pool={pool} userDataLoaded={userDataLoaded} />
      </ActionContainer>
    </StyledActionPanel>
  )
}

export default ActionPanel
