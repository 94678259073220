import { BigNumber } from 'ethers'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useMulticallContract } from './useContract'
import useSelectedChain from './useSelectedChain'

// gets the current timestamp from the blockchain
export default function useCurrentBlockTimestamp(): BigNumber | undefined {
  const { chainId } = useSelectedChain()
  const multicall = useMulticallContract(chainId)
  return useSingleCallResult(multicall, 'getCurrentBlockTimestamp')?.result?.[0]
}
