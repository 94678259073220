import { Web3ReactHooks } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { ConnectorNames } from '@unitydefi/uikit'
import { metaMask, hooks as metaMaskHooks } from './connectors/metaMask'
// import {
//   coinbaseWallet,
//   hooks as coinbaseWalletHooks,
// } from './connectors/coinbaseWallet'
// import { gnosisSafe, hooks as gnosisSafeHooks } from './connectors/gnosisSafe'
import { walletConnect, hooks as walletConnectHooks } from './connectors/walletConnectV2'

// export type ConnectorTypes =
//   | 'metamask'
//   // | 'coinbase'
//   | 'gnosis'
//   | 'walletConnect'
export const CONNECTORS: {
  [key in ConnectorNames]: [Connector, Web3ReactHooks]
} = {
  injected: [metaMask, metaMaskHooks],
  bsc: [metaMask, metaMaskHooks],
  //  walletconnect: [metaMask, metaMaskHooks],
  // coinbase: [coinbaseWallet, coinbaseWalletHooks],
  // gnosis: [gnosisSafe, gnosisSafeHooks],
  walletconnect: [walletConnect, walletConnectHooks],
}
