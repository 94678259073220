import { Address } from '../types'

export enum CollectionKey {
  REVOLUTIONIZE_UNITY = 'revolutionizeUnity',
  REVU_WARRIORS = 'revuWarriors',
  // SQUAD = 'unitydefiSquad',
}

export type Collection = {
  name: string
  description?: string
  slug: string
  address: Address
}

export type Collections = {
  [key in CollectionKey]: Collection
}
