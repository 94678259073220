import React, { useMemo } from 'react'
import { Flex, Skeleton, Text } from '@unitydefi/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'
import Balance from 'components/Balance'
import { DeserializedPool } from 'state/types'
import { useUVVault } from 'state/pools/hooks'
import { getBalanceNumber } from 'utils/formatBalance'
import BaseCell, { CellContent } from './BaseCell'

interface TotalStakedCellProps {
  pool: DeserializedPool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const TotalStakedCell: React.FC<TotalStakedCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { sousId, stakingToken, totalStaked, isAutoVault, isLpPool, stakingTokenPrice } = pool
  const { totalUVInVault } = useUVVault()

  const isManualUVPool = sousId === 0

  const totalStakedBalance = useMemo(() => {
    if (isAutoVault) {
      return getBalanceNumber(totalUVInVault, stakingToken.decimals)
    }
    if (isManualUVPool) {
      const manualUVTotalMinusAutoVault = new BigNumber(totalStaked).minus(totalUVInVault)
      return getBalanceNumber(manualUVTotalMinusAutoVault, stakingToken.decimals)
    }
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }, [isAutoVault, totalUVInVault, isManualUVPool, totalStaked, stakingToken.decimals])

  const totalStakedValue = useMemo(() => {
    return new BigNumber(stakingTokenPrice).multipliedBy(totalStakedBalance).toNumber()
  }, [totalStakedBalance, stakingTokenPrice])

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {isLpPool ? t('Total value') : t('Total staked')}
        </Text>
        {totalStaked && totalStaked.gte(0) ? (
          <Flex height="20px" alignItems="center">
            {isLpPool ? (
              <Balance fontSize="16px" value={totalStakedValue} decimals={0} prefix="$" />
            ) : (
              <Balance fontSize="16px" value={totalStakedBalance} decimals={0} unit={` ${stakingToken.symbol}`} />
            )}
          </Flex>
        ) : (
          <Skeleton width="80px" height="16px" />
        )}
      </CellContent>
    </StyledCell>
  )
}

export default TotalStakedCell
