import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import confetti from 'canvas-confetti'
import { Modal, Text, Button, Flex, InjectedModalProps, AutoRenewIcon } from '@unitydefi/uikit'
import { delay } from 'lodash'
import { useTranslation } from 'contexts/Localization'
import { NftAchievment } from 'config/constants/types'
import { useRevuSpecialV1Contract } from 'hooks/useContract'
import { resetUserNftState } from 'state/nftMarket/reducer'
import { useAppDispatch } from 'state'
import useRevuSpecialV1 from '../hooks/useRevuSpecialV1'

const NftImage = styled.img`
  border-radius: 50%;
  height: 128px;
  margin-bottom: 24px;
  margin-right: 8px;
  width: 128px;
`

const showConfetti = () => {
  confetti({
    particleCount: 200,
    startVelocity: 30,
    gravity: 0.5,
    spread: 350,
    origin: {
      x: 0.5,
      y: 0.3,
    },
  })
}

interface NftGiveawayModalProps extends InjectedModalProps {
  nfts: NftAchievment[]
}

const NftGiveawayModal: React.FC<NftGiveawayModalProps> = ({ onDismiss, nfts }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const lotteryNftContract = useRevuSpecialV1Contract()
  const { canClaimRYIUNft, canClaimWatcheriumNft } = useRevuSpecialV1()
  const dispatch = useAppDispatch()

  const handleClick1 = async () => {
    setIsLoading(true)
    try {
      const { canClaim, mintData } = await canClaimRYIUNft()
      if (canClaim) {
        const { nftId, lotteryId, cursor } = mintData
        await lotteryNftContract.mintNFT(nftId, lotteryId, cursor)
      }
      dispatch(resetUserNftState())
    } finally {
      onDismiss()
    }
  }

  const handleClick2 = async () => {
    setIsLoading(true)
    try {
      const { canClaim, mintData } = await canClaimWatcheriumNft()
      if (canClaim) {
        const { nftId, lotteryId, cursor } = mintData
        await lotteryNftContract.mintNFT(nftId, lotteryId, cursor)
      }
    } finally {
      onDismiss()
    }
  }

  useEffect(() => {
    delay(showConfetti, 100)
  }, [])

  const getImages = () => {
    return nfts.map((nft) => <NftImage key={nft.id} src={`/images/nfts/${nft.images.md}`} />)
  }

  const canClaimRYIU = nfts.some((p) => p.identifier === 'ryi-unity')
  const canClaimWatcherium = nfts.some((p) => p.identifier === 'watcherium')

  return (
    <Modal title={t('Congratulations!')} onDismiss={onDismiss}>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Flex flexWrap="wrap" alignItems="center" justifyContent="center">
          {getImages()}
        </Flex>
        <Text textAlign="center" bold color="secondary" fontSize="24px" mb="24px">
          {nfts.length > 1 ? t('You won multiple collectibles!') : t('You won a collectible!')}
        </Text>
        {canClaimRYIU ? (
          <Button
            disabled={isLoading}
            onClick={handleClick1}
            endIcon={isLoading ? <AutoRenewIcon spin color="currentColor" /> : undefined}
          >
            {isLoading ? t('Claiming...') : t('Claim RYI Unity now')}
          </Button>
        ) : (
          <></>
        )}
        {canClaimWatcherium ? (
          <Button
            disabled={isLoading}
            onClick={handleClick2}
            endIcon={isLoading ? <AutoRenewIcon spin color="currentColor" /> : undefined}
          >
            {isLoading ? t('Claiming...') : t('Claim Watcherium now')}
          </Button>
        ) : (
          <></>
        )}
      </Flex>
    </Modal>
  )
}

export default NftGiveawayModal
