import React, { FC, HTMLAttributes, useMemo } from 'react'
import { INftTokenInfo } from 'config/constants/types'
import { Text } from '@unitydefi/uikit'
import { DeserializedNftPool } from 'state/types'
import NftListItem from './NftListItem'
import './NftListItem.scss'

export type NftListProps = HTMLAttributes<any> & {
  pool: DeserializedNftPool
  tokens: INftTokenInfo[]
  selectedTokens: INftTokenInfo[]
  emptyLabel?: string
  toggleTokenSelection?: (token: INftTokenInfo) => void
}

const NftList: FC<NftListProps> = ({
  pool,
  tokens,
  selectedTokens,
  emptyLabel,
  toggleTokenSelection,
  className,
  ...props
}) => {
  const hasSelection = useMemo(() => selectedTokens?.length > 0, [selectedTokens])

  return (
    <div className={`nft-list ${hasSelection ? 'has-selection' : ''} ${className}`} {...props}>
      {pool.collection ? (
        <div className="nft-list-section">
          <Text fontSize="16px" className="section-title">
            {pool.collection.name}
          </Text>
          {tokens.map((token) => {
            const isSelected = !!selectedTokens.find((p) => p.token.tokenId === token.token.tokenId)

            return (
              <NftListItem
                isSelected={isSelected}
                key={`${token.token.collection}-${token.token.tokenId}`}
                onClick={toggleTokenSelection ? () => toggleTokenSelection(token) : undefined}
                token={token}
                pool={pool}
              />
            )
          })}
        </div>
      ) : (
        <div className="nft-list-empty">{emptyLabel}</div>
      )}
    </div>
  )
}

export default NftList
