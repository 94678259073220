import { Token } from '@pancakeswap/sdk'
import tokens from 'config/constants/tokens'
import { SupportedChainId } from './chains'

const { bondly, safemoon } = tokens[SupportedChainId.MAINNET]

interface WarningTokenList {
  [key: string]: Token
}

const SwapWarningTokens = <WarningTokenList>{
  safemoon,
  bondly,
}

export default SwapWarningTokens
