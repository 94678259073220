import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Unity Ventures',
  description:
    'The most popular AMM on BSC! Earn UV through yield farming or win it in the Lottery, then stake it in Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Unity Ventures), NFTs, and more, on a platform you can trust.',
  image: 'https://v1.unity-ventures.com/images/ui/share.jpg',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Unity Ventures')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('Unity Ventures')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('Unity Ventures')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('Unity Ventures')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('Unity Ventures')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('Unity Ventures')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Unity Ventures')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Unity Ventures')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('Unity Ventures')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Unity Ventures')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('Unity Ventures')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Unity Ventures')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Unity Ventures')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Unity Ventures')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Unity Ventures')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('Unity Ventures')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('Unity Ventures')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('Unity Ventures')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('Unity Ventures Info & Analytics')}`,
        description: 'View statistics for Unity Ventures exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('Unity Ventures Info & Analytics')}`,
        description: 'View statistics for Unity Ventures exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('Unity Ventures Info & Analytics')}`,
        description: 'View statistics for Unity Ventures exchanges.',
      }
    case '/referral':
      return {
        title: `${t('Referral')} | ${t('Unity Ventures')}`,
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('UnityVentures')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('UnityVentures')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('UnityVentures')}`,
      }
    default:
      return null
  }
}
