import React, { ReactNode, useEffect, useState } from 'react'
import { SupportedChainConfiguration, SUPPORTED_CHAINS_LIST, SUPPORTED_CHAINS } from 'config/constants/chains'
import { useWeb3React } from '@web3-react/core'

type SelectedChainContextType = {
  selectedChain: SupportedChainConfiguration
  setSelectedChain: (chain: SupportedChainConfiguration) => void
}

const defaultChain = SUPPORTED_CHAINS_LIST.find((p) => p.isDefault)
const SelectedChainContext = React.createContext<SelectedChainContextType>(undefined as any)

const SelectedChainContextProvider = ({ children }: { children: ReactNode }) => {
  const { chainId: walletChain } = useWeb3React()
  const [selectedChain, setSelectedChain] = useState<SupportedChainConfiguration>(defaultChain)

  useEffect(() => {
    // console.log({ walletChain })
    if (walletChain) {
      const chain = SUPPORTED_CHAINS[walletChain]
      if (chain) setSelectedChain(chain)
    }
  }, [walletChain])

  return (
    <SelectedChainContext.Provider value={{ selectedChain, setSelectedChain }}>
      {children}
    </SelectedChainContext.Provider>
  )
}

export { SelectedChainContext, SelectedChainContextProvider }
