import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Text, Flex, Skeleton, Heading } from '@unitydefi/uikit'
import { useWeb3React } from '@web3-react/core'
import { getBalanceNumber } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { BIG_ZERO } from 'utils/bigNumber'
import { DeserializedNftPool } from 'state/types'
import { useUserNfts } from 'state/nftStaking/hooks'
import useClaimRewards from 'views/NftStaking/hooks/useClaimRewards'
import useToast from 'hooks/useToast'
import { formatError } from 'utils/errors'
import { ActionContainer, ActionTitles, ActionContent } from './styles'

interface ClaimActionProps {
  pool: DeserializedNftPool
  userDataLoaded: boolean
}

const ClaimAction: React.FunctionComponent<ClaimActionProps> = ({ pool, userDataLoaded }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const [isClaiming, setIsClaiming] = useState(false)
  const { claimRewards } = useClaimRewards(pool)
  const { toastSuccess, toastError } = useToast()

  const { userData, earningToken } = pool
  const { nftTokens, userNftLoaded } = useUserNfts()
  const { pendingReward } = userData

  const stakeTokens = useMemo(() => {
    if (!nftTokens) return undefined

    return nftTokens.filter((p) => p.stakingInfo?.poolId === pool.sousId)
  }, [nftTokens, pool.sousId])

  const stakeInfos = useMemo(() => {
    if (!stakeTokens) return undefined

    return stakeTokens.map((p) => p.stakingInfo)
  }, [stakeTokens])

  const earnings = useMemo(
    () =>
      pool.mode === 'rewardPerBlock'
        ? pendingReward
        : stakeInfos && userNftLoaded
        ? stakeInfos.reduce((total, value) => total.plus(value.rewards).minus(value.claimed), BIG_ZERO)
        : BIG_ZERO,
    [pendingReward, pool.mode, stakeInfos, userNftLoaded],
  )
  const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  const hasEarnings = earnings.gt(0)

  // useEffect(() => {
  //   console.log({
  //     pool: pool.sousId,
  //     earnings,
  //     hasEarnings,
  //     stakeInfos,
  //     pendingReward: pendingReward.toString(),
  //   })
  // }, [earnings, hasEarnings, pendingReward, pool.sousId, stakeInfos])

  const doClaim = useCallback(async () => {
    if (stakeTokens) {
      setIsClaiming(true)
      try {
        await claimRewards(stakeTokens.map((p) => p.token.tokenId))
        toastSuccess(
          `${t('Claimed')}!`,
          t('Your %symbol% earnings have been claimed!', { symbol: earningToken.symbol }),
        )
      } catch (e) {
        toastError(t('Error'), formatError(e))
        console.error(e)
      } finally {
        setIsClaiming(false)
      }
    }
  }, [stakeTokens, claimRewards, toastSuccess, t, earningToken.symbol, toastError])

  // const [onPresentCollect] = useModal(
  //   <></>,
  //   // <CollectModal
  //   //   formattedBalance={formattedBalance}
  //   //   fullBalance={fullBalance}
  //   //   earningToken={earningToken}
  //   //   earningsDollarValue={earningTokenDollarBalance}
  //   //   sousId={sousId}
  //   //   isBnbPool={isBnbPool}
  //   //   isCompoundPool={isCompoundPool}
  //   // />,
  // )

  const actionTitle = (
    <>
      <Text fontSize="12px" bold color="secondary" as="span" textTransform="uppercase">
        {t('Claimable')}{' '}
      </Text>
      <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
        {earningToken.symbol}
      </Text>
    </>
  )

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Heading>0</Heading>
          <Button disabled>{t('Claim')}</Button>
        </ActionContent>
      </ActionContainer>
    )
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer>
      <ActionTitles>{actionTitle}</ActionTitles>
      <ActionContent>
        <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
          <>
            {hasEarnings ? (
              <>
                <Balance lineHeight="1" bold fontSize="20px" decimals={2} value={earningTokenBalance} />
              </>
            ) : (
              <>
                <Heading color="textDisabled">0</Heading>
              </>
            )}
          </>
        </Flex>
        <Button disabled={!hasEarnings || isClaiming} onClick={doClaim}>
          {t('Claim')}
        </Button>
      </ActionContent>
    </ActionContainer>
  )
}

export default ClaimAction
