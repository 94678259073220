import BigNumber from 'bignumber.js'
import LP_TOKEN_ABI from 'config/abi/lpToken.json'
import { SerializedPoolConfig, SerializedToken } from 'config/constants/types'
import multicall from 'utils/multicall'

const fetchLpPoolPrices = async (
  chainId: number,
  pools: SerializedPoolConfig[],
  tokens: SerializedToken[],
  tokenPrices: { [token: string]: BigNumber },
) => {
  const allCalls = pools.map((pool) => {
    const calls = [
      {
        address: pool.stakingToken.address,
        name: 'getReserves',
      },
      {
        address: pool.stakingToken.address,
        name: 'totalSupply',
      },
      {
        address: pool.stakingToken.address,
        name: 'token0',
      },
      {
        address: pool.stakingToken.address,
        name: 'token1',
      },
    ]

    return multicall(chainId, LP_TOKEN_ABI, calls)
  })

  const poolsWithPrices = (await Promise.all(allCalls)).map((result, index) => {
    const [[reserveToken0, reserveToken1], [totalSupply], [token0Address], [token1Address]] = result
    const pool = pools[index]

    const lpToken =
      tokens.find((p) => p.address.toLowerCase() === pool.stakingToken.address.toLowerCase()) || pool.stakingToken
    const token0 = tokens.find((p) => p.address.toLowerCase() === token0Address.toLowerCase())
    const token1 = tokens.find((p) => p.address.toLowerCase() === token1Address.toLowerCase())

    const token0Value = new BigNumber(reserveToken0.toString())
      .div(10 ** token0.decimals)
      .multipliedBy(tokenPrices[token0Address.toLowerCase()])
    const token1Value = new BigNumber(reserveToken1.toString())
      .div(10 ** token1.decimals)
      .multipliedBy(tokenPrices[token1Address.toLowerCase()])
    const totalValue = token0Value.plus(token1Value)
    const lpPriceBusd = totalValue.div(new BigNumber(totalSupply.toString()).div(10 ** lpToken.decimals))

    // console.log([reserveToken0, reserveToken1, totalSupply, token0Address, token1Address].map(p => p.toString()))
    // console.log([token0Value, token1Value, totalValue, lpPriceBusd].map(p => p.toString()))

    return {
      ...pool,
      lpPriceBusd: lpPriceBusd.toJSON(),
    }
  })

  return poolsWithPrices
}

export default fetchLpPoolPrices
