import { API_EXTENSIONS_NFT_POOLS } from 'config/constants/endpoints'
import pools from 'config/constants/nftPools'
import { serializeNftCollections } from 'config/constants/collections'
import { serializeTokens } from 'config/constants/tokens'
import { SupportedChainId } from 'config/constants/chains'

const serializedTokens = serializeTokens()
const serializedCollections = serializeNftCollections()

export const fetchNftExtensionPools = async () => {
  try {
    const result = await fetch(API_EXTENSIONS_NFT_POOLS)
    if (result.ok) {
      const data: {
        tokens: { [address: string]: any }
        pools: { [address: string]: any }
        lpHelper: { [address: string]: string }
      } = await result.json()
      const maxPoolId = pools.sort((a, b) => b.sousId - a.sousId)[0].sousId
      Object.values(data.pools).forEach((pool, idx) => {
        const chainId: SupportedChainId = pool.chainId || SupportedChainId.MAINNET
        if (pools.find((p) => p.contractAddress[chainId] === pool.address)) return
        let stakingToken = Object.values(serializedCollections).find((p) => p.address === pool.stakingToken)
        if (!stakingToken) {
          const token = data.tokens[pool.stakingToken]
          if (token) {
            stakingToken = {
              address: pool.stakingToken,
              chainId,
              name: token.name,
              subgraphUrl: token.subgraphUrl,
              ipfsPath: token.ipfsPath,
              projectLink: token.projectLink,
            }
            serializedCollections[pool.stakingToken] = stakingToken
          }
        }
        let earningToken = Object.values(serializedTokens[chainId]).find((p) => p.address === pool.earningToken)
        if (!earningToken) {
          const token = data.tokens[pool.earningToken]
          if (token) {
            earningToken = {
              address: pool.earningToken,
              chainId,
              decimals: token.decimals,
              name: token.name,
              symbol: token.symbol,
              projectLink: token.projectLink,
            }
            serializedTokens[chainId][earningToken.symbol] = earningToken
          }
        }

        pools.push({
          sousId: maxPoolId + idx + 1, // pool.poolId,
          collection: stakingToken,
          contractAddress: { [chainId]: pool.address },
          mode: pool.mode,
          earningToken,
          sortOrder: 999,
          chainId: pool.chainId || 56,
        })
      })
    }
  } catch (e) {
    console.error(e)
  }

  return {
    pools,
  }
}
