import React, { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers, Contract } from 'ethers'
import { useAppDispatch } from 'state'
import { updateUserNftAllowance } from 'state/actions'
import { useTranslation } from 'contexts/Localization'
import { useNftStakingVaultContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import useLastUpdated from 'hooks/useLastUpdated'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { ToastDescriptionWithTx } from 'components/Toast'

export const useApproveNftPool = (collectionContract: Contract, sousId) => {
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { account, chainId } = useWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice(chainId)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const stakingContract = useNftStakingVaultContract(sousId)

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const tx = await callWithGasPrice(collectionContract, 'setApprovalForAll', [stakingContract.address, true])
      const receipt = await tx.wait()

      dispatch(updateUserNftAllowance(sousId, account, chainId))
      if (receipt.status) {
        toastSuccess(
          t('Contract Enabled'),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t('You can now stake in the pool!', { symbol: '' })}
          </ToastDescriptionWithTx>,
        )
        setRequestedApproval(false)
      } else {
        // user rejected tx or didn't go thru
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
        setRequestedApproval(false)
      }
    } catch (e) {
      console.error(e)
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
    }
  }, [
    callWithGasPrice,
    collectionContract,
    stakingContract.address,
    dispatch,
    sousId,
    account,
    chainId,
    toastSuccess,
    t,
    toastError,
  ])

  return { handleApprove, requestedApproval }
}
