import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserNftPendingReward, updateUserNftStakedBalance } from 'state/actions'
import { DeserializedNftPool } from 'state/types'
import { useNftRewardPoolContract, useNftStakingVaultContract } from 'hooks/useContract'
import { fetchNftPoolsPublicDataAsync, updateUserNftBalance, updateUserNftRegistration } from 'state/nftStaking'
import useSelectedChain from 'hooks/useSelectedChain'

const useClaimRewards = (pool: DeserializedNftPool) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const { chainId } = useSelectedChain()
  const stakingContract = useNftStakingVaultContract(pool.sousId)
  const rewardContract = useNftRewardPoolContract(pool.sousId)

  const handleClaim = useCallback(
    async (tokenIds: number[]) => {
      const tx = pool.mode === 'duration' ? await stakingContract.claim(tokenIds) : await rewardContract.deposit([])
      await tx.wait()

      const { sousId } = pool
      const blockNumber = await stakingContract.provider.getBlockNumber()
      dispatch(fetchNftPoolsPublicDataAsync(chainId, blockNumber))
      dispatch(updateUserNftRegistration(sousId, account, chainId))
      dispatch(updateUserNftStakedBalance(sousId, account, chainId))
      dispatch(updateUserNftBalance(sousId, account, chainId))
      dispatch(updateUserNftPendingReward(sousId, account, chainId))
    },
    [account, dispatch, pool, rewardContract, stakingContract, chainId],
  )

  return { claimRewards: handleClaim }
}

export default useClaimRewards
