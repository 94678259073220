import { IStakingInfo } from 'config/constants/types'
import { BigNumber } from 'ethers'
import { DeserializedNftPool } from 'state/types'
import { getBalanceNumber } from 'utils/formatBalance'
import { BIG_ZERO } from 'utils/bigNumber'
import { BLOCKS_PER_DAY } from 'config'

export const getNftPoolBlockInfo = (pool: DeserializedNftPool, currentBlock: number) => {
  const { startBlock, endBlock, isFinished } = pool
  const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock)
  const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
  const blocksRemaining = Math.max(endBlock - currentBlock, 0)
  const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
  const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart
  return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay }
}

export const getStartDay = (pool: DeserializedNftPool, rewardIndex: number) => {
  let total = 0
  if (pool?.rewards) {
    for (let i = 0; i < rewardIndex; i++) {
      total = pool.rewards[i].duration
    }
  }

  return Math.floor(total / (24 * 60 * 60)) + 1
}

export const getEndDay = (pool: DeserializedNftPool, rewardIndex: number) => {
  let total = 0
  if (pool?.rewards) {
    for (let i = 0; i <= rewardIndex; i++) {
      total = pool.rewards[i].duration
    }
  }

  return Math.ceil(total / (24 * 60 * 60))
}

export const getDailyReward = (pool: DeserializedNftPool, rewardIndex: number) => {
  if (!pool?.rewards) return 0

  const previousDays = rewardIndex > 0 ? Math.ceil(pool.rewards[rewardIndex - 1].duration / (24 * 60 * 60)) : 0
  const poolDays = Math.ceil(pool.rewards[rewardIndex].duration / (24 * 60 * 60))
  const total = pool.rewards[rewardIndex].reward.div(poolDays - previousDays)

  return getBalanceNumber(total, pool.earningToken.decimals)
}

export const getTotalRewardBN = (pool: DeserializedNftPool) => {
  if (!pool?.rewards) return BIG_ZERO
  return pool.rewards.reduce((total, reward) => total.plus(reward.reward), BIG_ZERO)
}

export const getTotalReward = (pool: DeserializedNftPool) => {
  const total = getTotalRewardBN(pool)
  return getBalanceNumber(total, pool.earningToken.decimals)
}

export const getTotalDuration = (pool: DeserializedNftPool) => {
  if (!pool.rewards) return 0
  return Math.ceil(pool.rewards[pool.rewards.length - 1].duration / (24 * 60 * 60))
}

export const getTotalRewards = (pool: DeserializedNftPool) => {
  if (!pool.rewards) return 0
  return getBalanceNumber(
    pool.rewards.reduce((total, value) => total.plus(value.reward), BIG_ZERO),
    pool.earningToken.decimals,
  )
}

export const getCurrentDailyTotalRewards = (pool: DeserializedNftPool, stakeInfos: IStakingInfo[]) => {
  const daylyRewards =
    pool.mode === 'rewardPerBlock' && pool.rewardPerBlock
      ? stakeInfos.length
        ? pool.rewardPerBlock
            .multipliedBy(BLOCKS_PER_DAY)
            .div(pool.totalStaked || 1)
            .div(stakeInfos.length)
        : BIG_ZERO
      : stakeInfos.reduce((total, value) => total.plus(getCurrentDailyRewardBN(pool, value)), BIG_ZERO)
  return getBalanceNumber(daylyRewards, pool.earningToken.decimals)
}

export const getCurrentDailyRewardBN = (pool: DeserializedNftPool, stakeInfo: IStakingInfo) => {
  if (!stakeInfo || !pool?.rewards?.length || +stakeInfo.nextRewardDate < +new Date()) return BIG_ZERO

  const nbDays = Math.ceil((+new Date() - +stakeInfo.depositDate) / (24 * 60 * 60 * 1000))
  const poolDays = pool.rewards.map((p) => Math.ceil(p.duration / (24 * 60 * 60)))
  const rewardIndex = poolDays.indexOf(poolDays.find((p) => p >= nbDays) || 0)
  if (rewardIndex < 0) return BIG_ZERO

  const currentRewards = pool.rewards[rewardIndex]
  const daylyRewards = currentRewards.reward.div(poolDays[rewardIndex])

  //   console.log(`getDailyReward`, {
  //     depositDate: stakeInfo.depositDate,
  //     nbDays,
  //     poolDays,
  //     daylyRewards: formatUnits(daylyRewards, 9),
  //     currentRewards,
  //   });

  return daylyRewards
}

export const getCurrentDailyReward = (pool: DeserializedNftPool, stakeInfo: IStakingInfo) => {
  const daylyRewards = getCurrentDailyRewardBN(pool, stakeInfo)
  getBalanceNumber(daylyRewards, pool.earningToken.decimals)
}

export const getGasLimit = (suggested: BigNumber, ratio = 1.05, minGasLimit = 0) => {
  let gasLimit = suggested
  if (gasLimit?.toNumber()) {
    gasLimit = gasLimit.mul(Math.floor(100000 * ratio)).div(100000)
    if (gasLimit.lt(minGasLimit)) gasLimit = BigNumber.from(minGasLimit)

    // round and sign
    gasLimit = gasLimit.div(1000).mul(1000).add(4)
  }

  return gasLimit
}
