import React, { useMemo } from 'react'
import { Flex, TooltipText, Skeleton, useTooltip, Box, Text } from '@unitydefi/uikit'
import { useTranslation } from 'contexts/Localization'
import { DeserializedNftPool } from 'state/types'
import { getBalanceNumber } from 'utils/formatBalance'
import { getCurrentDailyTotalRewards } from 'views/NftStaking/helpers'
import { useFetchUserNfts, useUserNfts } from 'state/nftStaking/hooks'

interface UserRewardRowProps {
  pool: DeserializedNftPool
}

const UserRewardRow: React.FC<UserRewardRowProps> = ({ pool }) => {
  const { t } = useTranslation()
  const tooltipContent =
    pool.mode === 'rewardPerBlock' ? t('Your current estimated rewards per day') : t('Your current rewards per day')

  const { nftTokens, userNftLoaded } = useUserNfts()
  const stakeInfos = useMemo(() => {
    if (!nftTokens) return undefined

    return nftTokens.filter((p) => p.stakingInfo?.poolId === pool.sousId).map((p) => p.stakingInfo)
  }, [nftTokens, pool.sousId])

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-start' })
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        {tooltipVisible && tooltip}
        <TooltipText ref={targetRef}>{`${t('YOUR REWARDS')}:`}</TooltipText>
        {userNftLoaded && nftTokens ? (
          <div>
            {getCurrentDailyTotalRewards(pool, stakeInfos).toFixed(2)} {pool.earningToken.symbol} per day
          </div>
        ) : (
          <Skeleton width="82px" height="32px" />
        )}
      </Flex>
    </>
  )
}

export default UserRewardRow
