import BigNumber from 'bignumber.js'
import nftPoolsConfig from 'config/constants/nftPools'
import nftStakingVaultABI from 'config/abi/NftStakingVault.json'
import nftRewardPoolABI from 'config/abi/NftRewardPool.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'

interface PoolConfiguration {
  requiresRegistration: boolean
  startDepositBlock: BigNumber
  endDepositBlock: BigNumber
  maxTokensStaked: BigNumber
  maxTokensPerUser: BigNumber
  vestDuration: BigNumber
  registrationFee: BigNumber
}

export const fetchNftPoolsConfigurations = async (chainId: number) => {
  // console.log({ nftPoolsConfig })
  const selectedPools = [...nftPoolsConfig].filter((p) => p.chainId === chainId)
  const calls = selectedPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress, chainId),
      name: 'vaultConfiguration',
    }
  })

  const regCalls = selectedPools.map((poolConfig) => ({
    address: getAddress(poolConfig.contractAddress, chainId),
    name: 'registrationFee',
  }))

  const configurations: PoolConfiguration[] = await multicall(chainId, nftStakingVaultABI, calls)
  const fees: BigNumber[] = await multicall(chainId, nftStakingVaultABI, regCalls)
  return selectedPools.map((poolConfig, index) => {
    const {
      startDepositBlock,
      endDepositBlock,
      maxTokensStaked,
      maxTokensPerUser,
      vestDuration,
      requiresRegistration,
    } = configurations[index]
    const fee = fees[index]
    return {
      sousId: poolConfig.sousId,
      requiresRegistration,
      startBlock: new BigNumber(startDepositBlock.toString()).toJSON(),
      endBlock: new BigNumber(endDepositBlock.toString()).toJSON(),
      maxTokensStaked: new BigNumber(maxTokensStaked.toString()).toJSON(),
      maxTokensPerUser: new BigNumber(maxTokensPerUser.toString()).toJSON(),
      vestDuration: new BigNumber(vestDuration.toString()).toJSON(),
      registrationFee: new BigNumber(fee.toString()).toJSON(),
    }
  })
}

const fetchNftPoolsDurationRewards = async (chainId: number) => {
  const durationPools = nftPoolsConfig.filter((p) => p.mode === 'duration' && p.chainId === chainId)

  const lengthCalls = durationPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress, chainId),
      name: 'rewardsConfigurationLength',
    }
  })
  const lengths: BigNumber[] = (await multicall(chainId, nftStakingVaultABI, lengthCalls)).flatMap((p) => p)

  const calls = durationPools.flatMap((poolConfig, idx) =>
    [...Array(lengths[idx].toNumber())].map((_, i) => ({
      address: getAddress(poolConfig.contractAddress, chainId),
      name: 'rewardsConfiguration',
      params: [i],
    })),
  )
  const rewards = (await multicall(chainId, nftStakingVaultABI, calls)).flatMap((reward) => ({
    duration: reward.duration.toNumber(),
    reward: new BigNumber(reward.reward._hex).toJSON(),
  }))

  return durationPools.map((poolConfig, index) => {
    let startIndex: number
    let endIndex = lengths[0].toNumber()
    for (let i = 0; i < index; i++) {
      startIndex = endIndex
      endIndex += lengths[i].toNumber()
    }

    return {
      sousId: poolConfig.sousId,
      rewards: rewards.slice(startIndex, endIndex),
    }
  })
}

const fetchNftPoolsBlockRewards = async (chainId: number) => {
  const pools = nftPoolsConfig.filter((p) => p.mode === 'rewardPerBlock' && p.chainId === chainId)

  const calls = pools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress, chainId),
      name: 'rewardPerBlock',
    }
  })

  const rewards = await multicall(chainId, nftRewardPoolABI, calls)
  return pools.map((poolConfig, index) => ({
    sousId: poolConfig.sousId,
    rewardPerBlock: new BigNumber(rewards[index][0]._hex).toJSON(),
  }))
}

export const fetchNftPoolsRewards = async (chainId: number) => {
  const durationPoolRewards = await fetchNftPoolsDurationRewards(chainId)
  const blockPoolRewards = await fetchNftPoolsBlockRewards(chainId)

  return [...durationPoolRewards, ...blockPoolRewards]
}

export const fetchNftPoolsTotalStaking = async (chainId: number) => {
  const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
  const pools = chainPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress, chainId),
      name: 'tokensStaked',
      // params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const totalStaked = await multicall(chainId, nftStakingVaultABI, pools)

  return [
    ...chainPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(totalStaked[index]).toJSON(),
    })),
  ]
}
