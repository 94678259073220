import { IPFS_GATEWAY } from '../index'
import { Team } from './types'

const teams: Team[] = [
  {
    id: 1,
    name: 'Unified',
    description:
      'The tip of the NWO spear led by a globalist oligarchy driven to dominate and subjugate the entire metaverse, one universe and planet at a time. \n\nCapable of heinous acts to suit its agenda.',
    images: {
      lg: 'unified-lg.png',
      md: 'unified-md.png',
      sm: 'unified-sm.png',
      alt: 'unified-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmbUrcNyitPdYPLejVyLKAwJtXQXtjQJYMRu5dAm2ASnA6/unified.png`,
    },
    background: 'unified-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
  {
    id: 2,
    name: 'The Rebellious',
    description:
      'An ugly kid joe commoner sewn, defiant guerilla warfare unit devoted to raining down chaos on everything NWO. \n\nScrupulously equipped and sworn against the “agenda”.',
    images: {
      lg: 'the-rebellious-lg.png',
      md: 'the-rebellious-md.png',
      sm: 'the-rebellious-sm.png',
      alt: 'the-rebellious-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmbUrcNyitPdYPLejVyLKAwJtXQXtjQJYMRu5dAm2ASnA6/the-rebellious.png`,
    },
    background: 'rebellious-bg.svg',
    textColor: '#FFFFFF',
    users: 0,
    points: 0,
  },
  {
    id: 3,
    name: 'The MOB',
    description:
      'The men of bank, funded by the NWO, politicians and “personas non grata” together in an evil alliance to enslave every living organism in the metaverse. \n\nFormed over thousands of years and not about to roll over without a fight.',
    images: {
      lg: 'the-mob-lg.png',
      md: 'the-mob-md.png',
      sm: 'the-mob-sm.png',
      alt: 'the-mob-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmbUrcNyitPdYPLejVyLKAwJtXQXtjQJYMRu5dAm2ASnA6/the-mob.png`,
    },
    background: 'mob-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
  {
    id: 4,
    name: 'Metaverse Mercenary',
    description:
      'Ray guns for hire without allegiance to anything but gold, bitcoin and a few select tokenologies, the “Knights Templar” of the blockchain. \n\nOnce the OG’s of all things fiat finance, but now fallen and looking to worm their way back into power!',
    images: {
      lg: 'metaverse-mercenaries-lg.png',
      md: 'metaverse-mercenaries-md.png',
      sm: 'metaverse-mercenaries-sm.png',
      alt: 'metaverse-mercenaries-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmbUrcNyitPdYPLejVyLKAwJtXQXtjQJYMRu5dAm2ASnA6/metaverse-mercenaries.png`,
    },
    background: 'metaverse-mercenaries-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
  {
    id: 5,
    name: 'The Unexpected',
    description:
      'A dark force of unknown origin shapeshifters that nobody seems to understand or have contact with that are found in every corner of the metaverse.',
    images: {
      lg: 'the-unexpected-lg.png',
      md: 'the-unexpected-md.png',
      sm: 'the-unexpected-sm.png',
      alt: 'the-unexpected-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmbUrcNyitPdYPLejVyLKAwJtXQXtjQJYMRu5dAm2ASnA6/the-unexpected.png`,
    },
    background: 'the-unexpected-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
]

export default teams
