import React, { useCallback, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { Modal, Text, Flex, Button, AutoRenewIcon, Box } from '@unitydefi/uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import BigNumber from 'bignumber.js'
import { DeserializedNftPool } from 'state/types'
import { NftList } from 'components/NftList'
import useSelectedChain from 'hooks/useSelectedChain'
import { INftTokenInfo } from 'config/constants/types'
import { useFetchUserNfts, useUserNfts } from 'state/nftStaking/hooks'
import { getTotalDuration, getTotalRewardBN } from 'views/NftStaking/helpers'
import { getBalanceNumber } from 'utils/formatBalance'
import useNftStakePool from '../../../hooks/useNftStakePool'
import useNftUnstakePool from '../../../hooks/useNftUnstakePool'

interface StakeModalProps {
  pool: DeserializedNftPool
  stakingTokenBalance: BigNumber
  isRemovingStake?: boolean
  onDismiss?: () => void
}

const LoaderContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
`

const FooterContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const FlexFill = styled(Box)`
  flex: 1 1 auto;
`

const StakeModal: React.FC<StakeModalProps> = ({ pool, isRemovingStake = false, onDismiss }) => {
  const { account } = useWeb3React()
  const { chainId } = useSelectedChain()
  const { sousId, collection, maxTokensPerUser, earningToken } = pool
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { onStake } = useNftStakePool(sousId, pool.mode)
  const { onUnstake } = useNftUnstakePool(sousId, pool.mode, pool.enableEmergencyWithdraw)
  const { toastSuccess, toastError } = useToast()
  const [pendingTx, setPendingTx] = useState(false)
  const [selectedTokens, setSelectedTokens] = useState<INftTokenInfo[]>([])
  const [hasReachedStakeLimit, setHasReachedStakedLimit] = useState(false)

  useFetchUserNfts(chainId, account)

  const { nftTokens, userNftLoaded } = useUserNfts()
  const tokens = useMemo(() => {
    if (!nftTokens) return undefined

    return (
      isRemovingStake
        ? nftTokens.filter((p) => p.stakingInfo?.poolId === pool.sousId)
        : nftTokens.filter((p) => p.token.collection === pool.collection.address && !p.stakingInfo)
    ).sort((a, b) => (a.token.tokenId < b.token.tokenId ? -1 : a.token.tokenId > b.token.tokenId ? 1 : 0))
  }, [nftTokens, isRemovingStake, pool.sousId, pool.collection.address])

  const maxSelection = 10
  const toggleTokenSelection = useCallback(
    (token: INftTokenInfo) => {
      if (pendingTx) return
      if (selectedTokens.find((p) => p.token.tokenId === token.token.tokenId))
        setSelectedTokens(selectedTokens.filter((p) => p.token.tokenId !== token.token.tokenId))
      else if (selectedTokens.length < maxSelection) {
        setSelectedTokens([...selectedTokens, token])
      }
    },
    [pendingTx, selectedTokens],
  )

  const rewardPerDay = useMemo(() => getTotalRewardBN(pool), [pool])
  const expectedRewards = useMemo(() => {
    return isRemovingStake
      ? 0
      : getBalanceNumber(rewardPerDay.multipliedBy(selectedTokens?.length || 0), pool.earningToken.decimals)
  }, [isRemovingStake, rewardPerDay, selectedTokens?.length, pool.earningToken.decimals])

  // useEffect(() => {
  //   tokens.forEach((token) => {
  //     console.log(`token #${token.token.tokenId}, isSelected: ${selectedTokens.indexOf(token) > -1}`)
  //   })
  // }, [selectedTokens, tokens])

  // useEffect(() => {
  //   if (stakingLimit.gt(0) && !isRemovingStake) {
  //     setHasReachedStakedLimit(fullDecimalStakeAmount.plus(userData.stakedBalance).gt(stakingLimit))
  //   }
  // }, [
  //   stakeAmount,
  //   stakingLimit,
  //   userData,
  //   stakingToken,
  //   isRemovingStake,
  //   setHasReachedStakedLimit,
  //   fullDecimalStakeAmount,
  // ])

  const handleConfirmClick = async () => {
    setPendingTx(true)
    try {
      if (isRemovingStake) {
        // unstaking
        await onUnstake(selectedTokens.map((p) => p.token.tokenId))
        toastSuccess(
          `${t('Unstaked')}!`,
          t('Your %symbol% earnings have also been harvested to your wallet!', {
            symbol: earningToken.symbol,
          }),
        )
      } else {
        // staking
        const shouldRegister = pool.requiresRegistration && !pool.userData?.isRegistered
        const fee = shouldRegister ? pool.registrationFee : undefined
        await onStake(
          selectedTokens.map((p) => p.token.tokenId),
          fee,
        )
        toastSuccess(
          `${t('Staked')}!`,
          t('Your %symbol% NFTs have been staked in the pool!', {
            symbol: collection.name,
          }),
        )
      }
      setPendingTx(false)
      onDismiss()
    } catch (e) {
      console.error(e)
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setPendingTx(false)
    }
  }

  return (
    <Modal
      width={680}
      minWidth="346px"
      title={isRemovingStake ? t('Unstake') : t('Stake in Pool')}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      <div className="nft-list-container">
        {userNftLoaded ? (
          <NftList
            pool={pool}
            tokens={tokens}
            selectedTokens={selectedTokens}
            toggleTokenSelection={!pendingTx ? toggleTokenSelection : undefined}
            emptyLabel={
              isRemovingStake
                ? 'You do not have any NFT available to unstake'
                : 'You do not have any NFT available to stake'
            }
          />
        ) : (
          <LoaderContainer>
            <AutoRenewIcon spin color="currentColor" />
          </LoaderContainer>
        )}
      </div>

      {/* {maxTokensStaked > 0 && !isRemovingStake && (
        <Text color="secondary" bold mb="24px" style={{ textAlign: 'center' }} fontSize="16px">
          {t('Max stake for this pool: %amount% NFT', {
            amount: maxTokensStaked,
          })}
        </Text>
      )} */}

      {maxTokensPerUser > 0 && !isRemovingStake && (
        <Text color="secondary" bold mb="24px" style={{ textAlign: 'center' }} fontSize="16px">
          {t('Max stake per user this pool: %amount% NFT', {
            amount: maxTokensPerUser,
          })}
        </Text>
      )}
      <FooterContainer>
        <FlexFill mr="24px">
          {!isRemovingStake && selectedTokens?.length > 0 && pool.mode === 'duration' ? (
            <Text color="secondary" small>
              You will earn {expectedRewards} {pool.earningToken.symbol} in {getTotalDuration(pool)} days
            </Text>
          ) : (
            <Text color="secondary" small>
              Select up to {maxSelection} NFTs to {isRemovingStake ? 'unstake' : 'stake'}
            </Text>
          )}
        </FlexFill>
        <FlexFill ml="24px">
          <Button
            isLoading={pendingTx}
            endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
            onClick={handleConfirmClick}
            disabled={!selectedTokens || selectedTokens.length === 0 || hasReachedStakeLimit}
            style={{ width: '100%' }}
          >
            {pendingTx ? t('Confirming') : t('Confirm')}
          </Button>
        </FlexFill>
      </FooterContainer>
      {/* {!isRemovingStake && (
        <StyledLink external href={getTokenLink}>
          <Button width="100%" mt="8px" variant="secondary">
            {t('Get %symbol%', { symbol: stakingToken.symbol })}
          </Button>
        </StyledLink>
      )} */}
    </Modal>
  )
}

export default StakeModal
