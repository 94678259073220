import React, { useMemo } from 'react'
import styled from 'styled-components'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { Button, ChevronRightIcon, InjectedModalProps, Modal } from '@unitydefi/uikit'

const PanelContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 720px;
  max-width: 100%;
  display: flex;
  flex-direction: rows;

  animation: slide-in 1s ease-in;

  @keyframes slide-in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .hide {
    animation: slide-out 2s ease-in;
  }
`
const BackContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1000;
`
const FrameContainer = styled.iframe`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  background-color: #121212;
`
const CloseButton = styled(Button)`
  margin-top: 1rem;
`

export default function DeFiDashModal({ onDismiss }: InjectedModalProps) {
  const { account } = useActiveWeb3React()
  const url = useMemo(() => `https://defidash.unitydefi.com?address=${account}`, [account])

  return (
    <BackContainer>
      <PanelContainer className="slide-in">
        <CloseButton
          endIcon={<ChevronRightIcon color="white" height={48} width={48} />}
          onClick={onDismiss}
          style={{ background: 'transparent' }}
        />
        {account && <FrameContainer src={url} />}
      </PanelContainer>
    </BackContainer>
  )
}
