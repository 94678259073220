import { IPFS_GATEWAY } from 'config'
import { NftAchievment } from './types'

const Nfts: NftAchievment[] = [
  {
    name: 'RYI Unity',
    description: 'RYI Unity is the Unity In Community.',
    images: {
      lg: 'ryi-unity-lg.png',
      md: 'ryi-unity-md.png',
      sm: 'ryi-unity-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmeW6DQ7aesP9ugSWyUmGDzo1ujDeMwstou7a7iSTamFKo/ryi-unity.png`,
    },
    video: {
      webm: `${IPFS_GATEWAY}/QmeW6DQ7aesP9ugSWyUmGDzo1ujDeMwstou7a7iSTamFKo/ryi-unity.webm`,
      mp4: `${IPFS_GATEWAY}/QmeW6DQ7aesP9ugSWyUmGDzo1ujDeMwstou7a7iSTamFKo/ryi-unity.mp4`,
    },
    identifier: 'ryi-unity',
    id: 5,
  },
  {
    name: 'Watcherium',
    description: 'The Elite Rogue Warrior who stands against the MOB.',
    images: {
      lg: 'watcherium-lg.png',
      md: 'watcherium-md.png',
      sm: 'watcherium-sm.png',
      ipfs: `${IPFS_GATEWAY}/Qmb9ZRDEufnhb1uszdAigL5aFMhxxw7FomARtxTZxXzj3q/watcherium.png`,
    },
    video: {
      webm: `${IPFS_GATEWAY}/Qmb9ZRDEufnhb1uszdAigL5aFMhxxw7FomARtxTZxXzj3q/watcherium.webm`,
      mp4: `${IPFS_GATEWAY}/Qmb9ZRDEufnhb1uszdAigL5aFMhxxw7FomARtxTZxXzj3q/watcherium.mp4`,
    },
    identifier: 'watcherium',
    id: 6,
  },
]

export default Nfts
