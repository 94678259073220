import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { useWeb3React } from '@web3-react/core'
import { Flex, Image, Link, Menu as UikitMenu } from '@unitydefi/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import ChainSelector from 'components/Chains/chainSelector'
import DeFiDashButton from 'components/DeFiDash/DeFiDashButton'
import useTheme from 'hooks/useTheme'
import useSelectedChain from 'hooks/useSelectedChain'
import { usePriceUVBusd } from 'state/farms/hooks'
import styled from 'styled-components'
import config from './config/config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  .mobile-icon {
    width: 32px;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
  }
  .desktop-icon {
    width: 160px;
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
`

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const uvPriceUsd = usePriceUVBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()

  const { selectedChain } = useSelectedChain()
  const { account } = useWeb3React()
  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t, selectedChain.chainId), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const customLogo = useMemo(() => {
    return (
      <Flex>
        <StyledLink href="/" aria-label="Unity Ventures home page">
          <Image className="mobile-icon" src="/logo-unityventures-square.png" alt="" width={64} height={64} />
          <Image className="desktop-icon" src="/logo-unityventures-square.png" alt="" width={64} height={64} />
        </StyledLink>
      </Flex>
    )
  }, [])

  const customFooterLogo = useMemo(() => {
    return (
      <></>
      // <div style={{ width: '43px', height: '43px' }}>
      //   {/* <Image className="desktop-icon" src="/logo-unityventures-square.png" alt="" width={43} height={43} /> */}
      // </div>
    )
  }, [])

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={
        <>
          {account ? <DeFiDashButton /> : undefined}
          <ChainSelector />
          <GlobalSettings />
        </>
      }
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      uvPriceUsd={uvPriceUsd.toNumber()}
      links={config(t, selectedChain.chainId)}
      subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={footerLinks(t)}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyUVLabel={t('Buy UV')}
      customLogo={customLogo}
      customFooterLogo={customFooterLogo}
      {...props}
    />
  )
}

export default Menu
