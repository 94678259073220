import React, { useMemo } from 'react'

type ChainIconProps = {
  chain: string
  width: number
  height: number
  alt?: string
  style?: React.CSSProperties
}

export default function ChainIcon({ chain, alt, ...props }: ChainIconProps) {
  const icon = useMemo(() => `/images/chains/${chain}.svg`, [chain])
  return <img src={icon} alt={alt || chain} {...props} />
}
