import React from 'react'
import styled from 'styled-components'
import { Text, Flex, Heading, IconButton, ArrowBackIcon, NotificationDot } from '@unitydefi/uikit'
import { Link } from 'react-router-dom'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled.div`
  background: ${({ theme }) => theme.colors.gradients.cardHeader};
`
const BackgroundContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-image: url(/images/ui/line-b.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()

  return (
    <AppHeaderContainer>
      <BackgroundContainer>
        <Flex alignItems="center" mr={noConfig ? 0 : '16px'}>
          {backTo && (
            <IconButton as={Link} to={backTo}>
              <ArrowBackIcon width="32px" />
            </IconButton>
          )}
          <Flex flexDirection="column">
            <Heading as="h2" mb="8px">
              {title}
            </Heading>
            <Flex alignItems="center">
              {helper && <QuestionHelper text={helper} mr="4px" placement="top-start" />}
              <Text color="textSubtle" fontSize="14px">
                {subtitle}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {!noConfig && (
          <Flex alignItems="center">
            <NotificationDot show={expertMode}>
              <GlobalSettings />
            </NotificationDot>
            <Transactions />
          </Flex>
        )}
      </BackgroundContainer>
    </AppHeaderContainer>
  )
}

export default AppHeader
