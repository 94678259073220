import { SupportedChainId } from 'config/constants/chains'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS = {
  [SupportedChainId.MAINNET]: 'https://bscscan.com',
  [SupportedChainId.TESTNET]: 'https://testnet.bscscan.com',
}

// UV_PER_BLOCK details
// 40 UV is minted per block
// 20 UV per block is sent to Burn pool (A farm just for burning uv)
// 10 UV per block goes to UV syrup pool
// 9 UV per block goes to Yield farms and lottery
// UV_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// UV/Block in src/views/Home/components/UVDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const UV_PER_BLOCK = 0.035
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const UV_PER_YEAR = UV_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://v1.unity-ventures.com'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[SupportedChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 1500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANCAKE_BUNNIES_UPDATE_FREQUENCY = 8000
