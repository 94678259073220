import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { SupportedChainId } from 'config/constants/chains'
import farmsConfig from 'config/constants/farms'
import basePoolsConfig from 'config/constants/pools'
import { BIG_ZERO } from 'utils/bigNumber'
import { PoolsState, SerializedPool, UVVault, VaultFees, VaultUser, AppThunk } from 'state/types'
import { getPoolApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
import priceHelperPoolLpsConfig from 'config/constants/priceHelperPoolLps'
import fetchFarmsPrices from 'state/farms/fetchFarmsPrices'
import fetchFarms from 'state/farms/fetchFarms'
import { serializeTokens } from 'config/constants/tokens'
import { fetchPoolsBlockLimits, fetchPoolsStakingLimits, fetchPoolsTotalStaking } from './fetchPools'
import { fetchExtensionPools } from './fetchExtensionPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
} from './fetchPoolsUser'
import { fetchPublicVaultData, fetchVaultFees } from './fetchVaultPublic'
import fetchVaultUser from './fetchVaultUser'
import { getLpPoolPrices, getTokenPricesFromFarm } from './helpers'
import fetchLpPoolPrices from './fetchLpPoolPrices'

const serializedTokens = serializeTokens()

const initialState: PoolsState = {
  data: [...basePoolsConfig],
  userDataLoaded: false,
  uvVault: {
    totalShares: null,
    pricePerFullShare: null,
    totalUVInVault: null,
    estimatedUVBountyReward: null,
    totalPendingUVHarvest: null,
    fees: {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    },
    userData: {
      isLoading: true,
      userShares: null,
      uvAtLastUserAction: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
    },
  },
}

const getPoolsConfig = async () => {
  return basePoolsConfig
}

// Thunks
export const fetchPoolsPublicDataAsync = (chainId: number, currentBlock: number) => async (dispatch, getState) => {
  const poolsConfig = await getPoolsConfig()
  const chainPools = poolsConfig.filter((p) => p.chainId === chainId)
  const blockLimits = await fetchPoolsBlockLimits(chainId)
  const totalStakings = await fetchPoolsTotalStaking(chainId)

  // changed : load helper prices
  const farmsToFetch = farmsConfig.filter((farmConfig) => farmConfig.pid === 2)
  const farmsWithPriceHelpers = farmsToFetch.concat(
    priceHelperPoolLpsConfig.filter((p) => (p.token.chainId || SupportedChainId.MAINNET) === chainId),
  )

  const farms = await fetchFarms(farmsWithPriceHelpers)
  const farmsWithPrices = await fetchFarmsPrices(farms)
  const farmPrices = { ...getTokenPricesFromFarm(getState().farms.data), ...getTokenPricesFromFarm(farmsWithPrices) }

  const lpPools = chainPools.filter((p) => p.isLpPool)
  const lpPoolsWithPrices = await fetchLpPoolPrices(
    chainId,
    lpPools,
    Object.values(serializedTokens[chainId]),
    farmPrices,
  )

  const prices = { ...farmPrices, ...getLpPoolPrices(lpPoolsWithPrices) }

  const liveData = chainPools.map((pool) => {
    const blockLimit = blockLimits.find((entry) => entry.sousId === pool.sousId)
    const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId)
    const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
    const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

    const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null
    const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

    const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null
    const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
    const apr = !isPoolFinished
      ? getPoolApr(
          stakingTokenPrice,
          earningTokenPrice,
          getBalanceNumber(new BigNumber(totalStaking?.totalStaked || '0'), pool.stakingToken.decimals),
          parseFloat(pool.tokenPerBlock),
        )
      : 0

    return {
      ...blockLimit,
      ...totalStaking,
      stakingTokenPrice,
      earningTokenPrice,
      apr,
      isFinished: isPoolFinished,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const fetchPoolsExtensionsDataAsync = () => async (dispatch) => {
  const extensionData = await fetchExtensionPools()
  dispatch(setPoolsExtensionData(extensionData))
}

export const fetchPoolsStakingLimitsAsync = (chainId: number) => async (dispatch, getState) => {
  const poolsConfig = await getPoolsConfig()
  const poolsWithStakingLimit = getState()
    .pools.data.filter(
      ({ chainId: poolChain, stakingLimit }) =>
        poolChain === chainId && stakingLimit !== null && stakingLimit !== undefined,
    )
    .map((pool) => pool.sousId)

  const stakingLimits = await fetchPoolsStakingLimits(chainId, poolsWithStakingLimit)

  const stakingLimitData = poolsConfig.map((pool) => {
    if (poolsWithStakingLimit.includes(pool.sousId)) {
      return { sousId: pool.sousId }
    }
    const stakingLimit = stakingLimits[pool.sousId] || BIG_ZERO
    return {
      sousId: pool.sousId,
      stakingLimit: stakingLimit.toJSON(),
    }
  })

  dispatch(setPoolsPublicData(stakingLimitData))
}

export const fetchPoolsUserDataAsync =
  (account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const poolsConfig = await getPoolsConfig()
    const chainPools = poolsConfig.filter((p) => p.chainId === chainId)

    const allowances = await fetchPoolsAllowance(chainId, account)
    const stakingTokenBalances = await fetchUserBalances(chainId, account)
    const stakedBalances = await fetchUserStakeBalances(chainId, account)
    const pendingRewards = await fetchUserPendingRewards(chainId, account)

    const userData = chainPools.map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
    }))

    dispatch(setPoolsUserData(userData))
  }

export const updateUserAllowance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(chainId, account)
    dispatch(updatePoolsUserData({ sousId, field: 'allowance', value: allowances[sousId] }))
  }

export const updateUserBalance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances(chainId, account)
    dispatch(updatePoolsUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }))
  }

export const updateUserStakedBalance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(chainId, account)
    dispatch(updatePoolsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }))
  }

export const updateUserPendingReward =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(chainId, account)
    dispatch(updatePoolsUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }))
  }

export const fetchUVVaultPublicData = createAsyncThunk<UVVault>('uvVault/fetchPublicData', async () => {
  const publicVaultInfo = await fetchPublicVaultData()
  return publicVaultInfo
})

export const fetchUVVaultFees = createAsyncThunk<VaultFees>('uvVault/fetchFees', async () => {
  const vaultFees = await fetchVaultFees()
  return vaultFees
})

export const fetchUVVaultUserData = createAsyncThunk<VaultUser, { account: string }>(
  'uvVault/fetchUser',
  async ({ account }) => {
    const userData = await fetchVaultUser(account)
    return userData
  },
)

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsExtensionData: (state, action) => {
      const { pools }: { pools: SerializedPool[] } = action.payload

      pools.forEach((pool) => {
        const index = state.data.findIndex((p) => p.sousId === pool.sousId)
        if (index >= 0) {
          state.data[index] = { ...pool, ...state.data[index] }
        } else {
          state.data.unshift({ ...pool })
        }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
  extraReducers: (builder) => {
    // Vault public data that updates frequently
    builder.addCase(fetchUVVaultPublicData.fulfilled, (state, action: PayloadAction<UVVault>) => {
      state.uvVault = { ...state.uvVault, ...action.payload }
    })
    // Vault fees
    builder.addCase(fetchUVVaultFees.fulfilled, (state, action: PayloadAction<VaultFees>) => {
      const fees = action.payload
      state.uvVault = { ...state.uvVault, fees }
    })
    // Vault user data
    builder.addCase(fetchUVVaultUserData.fulfilled, (state, action: PayloadAction<VaultUser>) => {
      const userData = action.payload
      userData.isLoading = false
      state.uvVault = { ...state.uvVault, userData }
    })
  },
})

// Actions
export const { setPoolsPublicData, setPoolsExtensionData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions

export default PoolsSlice.reducer
