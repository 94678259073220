import { useEffect, useState } from 'react'

type UseReferrerState = {
  referrer?: string
}

const useReferrer = () => {
  const [referrerState, setReferrerState] = useState<UseReferrerState>({})

  const location = window.location.href

  useEffect(() => {
    const referrer = location.split('uv-friend=')[1]

    if (referrer) {
      setReferrerState({
        referrer,
      })
    }
  }, [location])

  return referrerState?.referrer
}

export default useReferrer
