import { CurrencyAmount, SwapParameters, TradeOptions, TradeOptionsDeadline, TradeType } from '@pancakeswap/sdk'
import { ETHER_TOKENS } from 'config/constants/chains'
import invariant from 'tiny-invariant'
import { Trade } from './trades'
import { validateAndParseAddress } from './utils'

const ZERO_HEX = '0x0'

function toHex(currencyAmount: CurrencyAmount) {
  return `0x${currencyAmount.raw.toString(16)}`
}

export function swapCallParameters(trade: Trade, options: TradeOptions | TradeOptionsDeadline): SwapParameters {
  const etherIn = trade.inputAmount.currency === ETHER_TOKENS[trade.route.chainId]
  const etherOut = trade.outputAmount.currency === ETHER_TOKENS[trade.route.chainId]

  // the router does not support both ether in and out
  invariant(!(etherIn && etherOut), 'ETHER_IN_OUT')
  invariant(!('ttl' in options) || options.ttl > 0, 'TTL')

  const to: string = validateAndParseAddress(options.recipient)
  const amountIn: string = toHex(trade.maximumAmountIn(options.allowedSlippage))
  const amountOut: string = toHex(trade.minimumAmountOut(options.allowedSlippage))
  const path: string[] = trade.route.path.map((token) => token.address)
  const deadline =
    'ttl' in options
      ? `0x${(Math.floor(new Date().getTime() / 1000) + options.ttl).toString(16)}`
      : `0x${options.deadline.toString(16)}`

  const useFeeOnTransfer = Boolean(options.feeOnTransfer)

  let methodName: string
  let args: (string | string[])[]
  let value: string
  switch (trade.tradeType) {
    case TradeType.EXACT_INPUT:
      if (etherIn) {
        methodName = useFeeOnTransfer ? 'swapExactETHForTokensSupportingFeeOnTransferTokens' : 'swapExactETHForTokens'
        // (uint amountOutMin, address[] calldata path, address to, uint deadline)
        args = [amountOut, path, to, deadline]
        value = amountIn
      } else if (etherOut) {
        methodName = useFeeOnTransfer ? 'swapExactTokensForETHSupportingFeeOnTransferTokens' : 'swapExactTokensForETH'
        // (uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline)
        args = [amountIn, amountOut, path, to, deadline]
        value = ZERO_HEX
      } else {
        methodName = useFeeOnTransfer
          ? 'swapExactTokensForTokensSupportingFeeOnTransferTokens'
          : 'swapExactTokensForTokens'
        // (uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline)
        args = [amountIn, amountOut, path, to, deadline]
        value = ZERO_HEX
      }
      break
    case TradeType.EXACT_OUTPUT:
      invariant(!useFeeOnTransfer, 'EXACT_OUT_FOT')
      if (etherIn) {
        methodName = 'swapETHForExactTokens'
        // (uint amountOut, address[] calldata path, address to, uint deadline)
        args = [amountOut, path, to, deadline]
        value = amountIn
      } else if (etherOut) {
        methodName = 'swapTokensForExactETH'
        // (uint amountOut, uint amountInMax, address[] calldata path, address to, uint deadline)
        args = [amountOut, amountIn, path, to, deadline]
        value = ZERO_HEX
      } else {
        methodName = 'swapTokensForExactTokens'
        // (uint amountOut, uint amountInMax, address[] calldata path, address to, uint deadline)
        args = [amountOut, amountIn, path, to, deadline]
        value = ZERO_HEX
      }
      break

    default:
      throw new Error('invalid')
  }
  return {
    methodName,
    args,
    value,
  }
}
