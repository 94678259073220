import React from 'react'
import { CardHeader, Heading, Text, Flex } from '@unitydefi/uikit'
import { Token } from '@pancakeswap/sdk'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { TokenPairImage } from 'components/TokenImage'
import UVVaultTokenPairImage from '../UVVaultCard/UVVaultTokenPairImage'

const Wrapper = styled(CardHeader)`
  background-image: url(/images/ui/line-b.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  flex: 1 1 auto;

  border-radius: ${({ theme }) => `${theme.radii.card} ${theme.radii.card} 0 0`};
`

const BgWrapper = styled(Flex)<{ isFinished?: boolean; background?: string }>`
  background: ${({ isFinished, background, theme }) =>
    isFinished ? theme.colors.backgroundDisabled : theme.colors.gradients[background]};
`
const StyledCardHeader: React.FC<{
  earningToken: Token
  stakingToken: Token
  isAutoVault?: boolean
  isFinished?: boolean
  isStaking?: boolean
}> = ({ earningToken, stakingToken, isFinished = false, isAutoVault = false, isStaking = false }) => {
  const { t } = useTranslation()
  const isUVPool = earningToken.symbol === 'UV' && stakingToken.symbol === 'UV'
  const background = isStaking ? 'dark' : 'cardHeader'

  const getHeadingPrefix = () => {
    if (isAutoVault) {
      // vault
      return t('Auto')
    }
    if (isUVPool) {
      // manual uv
      return t('Manual')
    }
    // all other pools
    return t('Earn')
  }

  const getSubHeading = () => {
    if (isAutoVault) {
      return t('Automatic restaking')
    }
    if (isUVPool) {
      return t('Earn UV, stake UV')
    }
    return t('Stake %symbol%', { symbol: stakingToken.symbol })
  }

  return (
    <BgWrapper isFinished={isFinished} background={background}>
      <Wrapper>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex flexDirection="column">
            <Heading color={isFinished ? 'textDisabled' : 'text'} scale="lg">
              {`${getHeadingPrefix()} ${earningToken.symbol}`}
            </Heading>
            <Text color={isFinished ? 'textDisabled' : 'textSubtle'}>{getSubHeading()}</Text>
          </Flex>
          {isAutoVault ? (
            <UVVaultTokenPairImage width={64} height={64} />
          ) : (
            <TokenPairImage primaryToken={earningToken} secondaryToken={stakingToken} width={64} height={64} />
          )}
        </Flex>
      </Wrapper>
    </BgWrapper>
  )
}

export default StyledCardHeader
