import { ethers } from 'ethers'
import { poolsConfig as basePoolsConfig, nftPoolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'
import tokens from 'config/constants/tokens'

// Addresses
import {
  getAddress,
  getUnityDefiProfileAddress,
  getUnityREVUAddress,
  getRevuFactoryAddress,
  getBunnySpecialAddress,
  getLotteryV2Address,
  getMasterChefAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getUVVaultAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getBunnySpecialUVVaultAddress,
  getBunnySpecialPredictionAddress,
  getBunnySpecialLotteryAddress,
  getFarmAuctionAddress,
  getAnniversaryAchievement,
  getNftMarketAddress,
  getNftSaleAddress,
  getReferralAddress,
  getRevuSpecialV1Address,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/unitydefiProfile.json'
import unityREVUAbi from 'config/abi/unityREVU.json'
import revuFactoryAbi from 'config/abi/revuFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import uvAbi from 'config/abi/uv.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import easterNftAbi from 'config/abi/easterNft.json'
import uvVaultAbi from 'config/abi/uvVault.json'
import predictionsAbi from 'config/abi/predictions.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialUVVaultAbi from 'config/abi/bunnySpecialUVVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import bunnySpecialLotteryAbi from 'config/abi/bunnySpecialLottery.json'
import revuSpecialV1Abi from 'config/abi/revuSpecialV1.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import anniversaryAchievementAbi from 'config/abi/anniversaryAchievement.json'
import nftMarketAbi from 'config/abi/nftMarket.json'
import nftSaleAbi from 'config/abi/nftSale.json'
import referralAbi from 'config/abi/referral.json'
import erc721CollctionAbi from 'config/abi/erc721collection.json'
import nftStakingVaultABI from 'config/abi/NftStakingVault.json'
import nftRewardPoolABI from 'config/abi/NftRewardPool.json'
import { getRpcProvider } from 'lib/entities/providers'

import { ChainLinkOracleContract, FarmAuctionContract, UnityDefiProfileContract, PredictionsContract } from './types'

const getPoolsConfig = () => {
  const poolsConfig = basePoolsConfig
  return poolsConfig
}

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
  chainId?: number,
) => {
  const signerOrProvider = signer ?? getRpcProvider(chainId || 56)
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const poolsConfig = getPoolsConfig()
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress, config.chainId), signer, config.chainId)
}
export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const poolsConfig = getPoolsConfig()
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress, config.chainId), signer, config.chainId)
}
export const getPointCenterIfoContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer)
}
export const getUVContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(uvAbi, tokens[56].uv.address, signer)
}
export const getProfileContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(profileABI, getUnityDefiProfileAddress(), signer) as UnityDefiProfileContract
}
export const getUnityDefiRabbitContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(unityREVUAbi, getUnityREVUAddress(), signer)
}
export const getRevuFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(revuFactoryAbi, getRevuFactoryAddress(), signer)
}
export const getBunnySpecialContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer)
}
export const getLotteryV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryV2Abi, getLotteryV2Address(), signer)
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getClaimRefundContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer)
}
export const getTradingCompetitionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer)
}
export const getEasterNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer)
}
export const getUVVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(uvVaultAbi, getUVVaultAddress(), signer)
}

export const getPredictionsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer) as PredictionsContract
}

export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainLinkOracleContract
}
export const getMulticallContract = (chainId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(chainId), signer, chainId)
}
export const getBunnySpecialUVVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialUVVaultAbi, getBunnySpecialUVVaultAddress(), signer)
}
export const getBunnySpecialPredictionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialPredictionAbi, getBunnySpecialPredictionAddress(), signer)
}
export const getBunnySpecialLotteryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialLotteryAbi, getBunnySpecialLotteryAddress(), signer)
}
export const getRevuSpecialV1Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(revuSpecialV1Abi, getRevuSpecialV1Address(), signer)
}
export const getFarmAuctionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(farmAuctionAbi, getFarmAuctionAddress(), signer) as FarmAuctionContract
}
export const getAnniversaryAchievementContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(anniversaryAchievementAbi, getAnniversaryAchievement(), signer)
}
export const getNftMarketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftMarketAbi, getNftMarketAddress(), signer)
}
export const getNftSaleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(nftSaleAbi, getNftSaleAddress(), signer)
}
export const getErc721CollectionContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(erc721CollctionAbi, address, signer)
}
export const getReferralContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(referralAbi, getReferralAddress(), signer)
}
export const getNftStakingContract = (signer?: ethers.Signer | ethers.providers.Provider, address?: string) => {
  return getContract(nftStakingVaultABI, address, signer)
}
export const getNftStakingVaultContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = nftPoolsConfig.find((pool) => pool.sousId === id)
  return getContract(nftStakingVaultABI, getAddress(config.contractAddress, config.chainId), signer, config.chainId)
}

export const getNftRewardPoolContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = nftPoolsConfig.find((pool) => pool.sousId === id)
  return getContract(nftRewardPoolABI, getAddress(config.contractAddress, config.chainId), signer, config.chainId)
}
