import { DropdownMenuItemType, MenuItemsType } from '@unitydefi/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t'], chainId: number) => ConfigMenuItemsType[] = (t, chainId) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      ...(chainId === 56
        ? [
            {
              label: t('Liquidity'),
              href: '/liquidity',
            },
          ]
        : []),
      // {
      //   label: t('Cross Chain'),
      //   href: '/cross-chain',
      // },
      {
        label: t('Buy Crypto'),
        href: '/buy-crypto',
        // status: {
        //   text: t('New'),
        //   color: 'success',
        // },
      },
      {
        label: t('Crypto Travel Care'),
        href: 'https://www.travelcare.io',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/pools',
    icon: 'Earn',
    items: [
      {
        label: t('Pools'),
        href: '/pools',
      },
      ...(chainId === 56
        ? [
            {
              label: t('Farms'),
              href: '/farms',
            },
          ]
        : []),
      {
        label: t('NFT'),
        href: '/nft-staking',
      },
    ],
  },
  ...(chainId === 56
    ? ([
        // {
        //   label: t('Win'),
        //   href: '/lottery',
        //   icon: 'Trophy',
        //   items: [
        //     {
        //       label: t('Lottery'),
        //       href: '/lottery',
        //     },
        //     {
        //       label: t('Referral'),
        //       href: '/referral',
        //     },
        //   ],
        // },
        {
          label: t('NFT'),
          href: `${nftsBaseUrl}`,
          icon: 'Nft',
          items: [
            {
              label: t('Overview'),
              href: `${nftsBaseUrl}`,
              status: {
                text: t('Live'),
                color: 'failure',
              },
            },
            {
              label: t('Collections'),
              href: `${nftsBaseUrl}/collections`,
            },
            {
              label: t('Staking'),
              href: '/nft-staking',
            },
            {
              label: t('Store'),
              href: 'https://store.unitydefi.com',
              // target: '_blank',
              type: DropdownMenuItemType.EXTERNAL_LINK,
            },
          ],
        },
      ] as any)
    : []),
  // {
  //   label: t('DeFiDash'),
  //   href: 'https://defidash.unitydefi.com',
  //   icon: 'Wallet',
  //   type: DropdownMenuItemType.EXTERNAL_LINK,
  //   items: []
  // },
]

export default config
