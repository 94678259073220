import React from 'react'
import styled from 'styled-components'
import { Flex, TooltipText, Skeleton, useTooltip, Box, Text } from '@unitydefi/uikit'
import { useTranslation } from 'contexts/Localization'
import { DeserializedNftPool } from 'state/types'
import BigNumber from 'bignumber.js'
import { getDailyReward, getEndDay, getStartDay, getTotalDuration, getTotalReward } from 'views/NftStaking/helpers'

const ApyLabelContainer = styled(Flex)`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

const RewardContainer = styled(Box)`
  width: 210px;
`

const TitleContainer = styled.span`
  font-size: 0.875rem;
`

interface RewardRowProps {
  pool: DeserializedNftPool
}

const RewardRow: React.FC<RewardRowProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { earningToken, isFinished, rewards } = pool

  const tooltipContent = (
    <RewardContainer>
      {(rewards || []).map((reward, idx) => (
        <Flex alignItems="center" justifyContent="space-between" key={`${idx + 1}`}>
          <Text small>
            Day {getStartDay(pool, idx)} to {getEndDay(pool, idx)}
          </Text>
          <Text small>
            {getDailyReward(pool, idx)} {earningToken.symbol}/day
          </Text>
        </Flex>
      ))}
    </RewardContainer>
  )

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-start' })

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        {tooltipVisible && tooltip}
        <TooltipText ref={targetRef}>
          <TitleContainer>{`${t('REWARDS')}:`}</TitleContainer>
        </TooltipText>
        {rewards || isFinished ? (
          <div>
            {getTotalReward(pool)} {earningToken.symbol} in {getTotalDuration(pool)} days
          </div>
        ) : (
          <Skeleton width="82px" height="32px" />
        )}
      </Flex>
    </>
  )
}

export default RewardRow
