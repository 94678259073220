import React, { useMemo } from 'react'
import { Flex, Text, Button, IconButton, AddIcon, MinusIcon, useModal, Skeleton, useTooltip } from '@unitydefi/uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import { DeserializedNftPool } from 'state/types'
import Balance from 'components/Balance'
import { useBlock } from 'state/block/hooks'
import StakeModal from '../Modals/StakeModal'

interface StakeActionsProps {
  pool: DeserializedNftPool
  availableBalance: BigNumber
  stakedBalance: BigNumber
  isStaked: ConstrainBoolean
  isLoading?: boolean
}

const StakeAction: React.FC<StakeActionsProps> = ({
  pool,
  availableBalance,
  stakedBalance,
  isStaked,
  isLoading = false,
}) => {
  const { maxTokensPerUser, isFinished, userData, startBlock } = pool
  const { t } = useTranslation()
  const stakedTokenBalance = stakedBalance?.toNumber() || 0
  const { currentBlock } = useBlock()

  const [onPresentTokenRequired] = useModal(
    <></>,
    // <NotEnoughTokensModal tokenSymbol={stakingToken.symbol} />
  )

  const [onPresentStake] = useModal(<StakeModal pool={pool} stakingTokenBalance={availableBalance} />)

  const [onPresentUnstake] = useModal(<StakeModal stakingTokenBalance={availableBalance} pool={pool} isRemovingStake />)

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('You’ve already staked the maximum amount you can stake in this pool!'),
    { placement: 'bottom' },
  )

  const isStarted = useMemo(() => {
    return startBlock <= currentBlock
  }, [startBlock, currentBlock])

  const reachStakingLimit = maxTokensPerUser && maxTokensPerUser > userData.stakedBalance

  const renderStakeAction = () => {
    return isStaked ? (
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <>
            <Balance bold fontSize="20px" decimals={0} value={stakedTokenBalance} />
          </>
        </Flex>
        <Flex>
          <IconButton variant="secondary" onClick={onPresentUnstake} mr="6px">
            <MinusIcon color="primary" width="24px" />
          </IconButton>
          {reachStakingLimit ? (
            <span ref={targetRef}>
              <IconButton variant="secondary" disabled>
                <AddIcon color="textDisabled" width="24px" height="24px" />
              </IconButton>
            </span>
          ) : (
            <IconButton
              variant="secondary"
              onClick={availableBalance.gt(0) ? onPresentStake : onPresentTokenRequired}
              disabled={isFinished}
            >
              <AddIcon color="primary" width="24px" height="24px" />
            </IconButton>
          )}
        </Flex>
        {tooltipVisible && tooltip}
      </Flex>
    ) : (
      <Button
        disabled={!isStarted || isFinished}
        onClick={availableBalance.gt(0) ? onPresentStake : onPresentTokenRequired}
      >
        {t('Stake')}
      </Button>
    )
  }

  return <Flex flexDirection="column">{isLoading ? <Skeleton width="100%" height="52px" /> : renderStakeAction()}</Flex>
}

export default StakeAction
