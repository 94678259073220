import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Flex, CardFooter, ExpandableLabel, HelpIcon, useTooltip } from '@unitydefi/uikit'
import { DeserializedNftPool } from 'state/types'
import ExpandedFooter from './ExpandedFooter'

interface FooterProps {
  pool: DeserializedNftPool
  account: string
  totalUVInVault?: BigNumber
}

const ExpandableButtonWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  button {
    padding: 0;
  }
`

const Footer: React.FC<FooterProps> = ({ pool, account }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  // const tooltipText = t('')

  // const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipText, {
  //   placement: 'bottom',
  // })

  return (
    <CardFooter>
      <ExpandableButtonWrapper>
        <Flex alignItems="center">
          {/* {isAutoVault ? <CompoundingPoolTag /> : <ManualPoolTag />} */}
          {/* {tooltipVisible && tooltip} */}
          {/* <Flex ref={targetRef}>
            <HelpIcon ml="4px" width="20px" height="20px" color="textSubtle" />
          </Flex> */}
        </Flex>
        <ExpandableLabel expanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? t('Hide') : t('Details')}
        </ExpandableLabel>
      </ExpandableButtonWrapper>
      {isExpanded && <ExpandedFooter pool={pool} account={account} />}
    </CardFooter>
  )
}

export default Footer
