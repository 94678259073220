import React from 'react'
import { CardHeader, Heading, Text, Flex } from '@unitydefi/uikit'
import { Token } from '@pancakeswap/sdk'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { NftCollection } from 'config/constants/types'
import { NftCollectionImage } from 'components/TokenImage'

const Wrapper = styled(CardHeader)`
  background-image: url(/images/ui/line-b.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;

  border-radius: ${({ theme }) => `${theme.radii.card} ${theme.radii.card} 0 0`};
`

const BgWrapper = styled(Flex)<{ isFinished?: boolean; background?: string }>`
  background: ${({ isFinished, background, theme }) =>
    isFinished ? theme.colors.backgroundDisabled : theme.colors.gradients[background]};
`

const StyledCardHeader: React.FC<{
  earningToken: Token
  collection: NftCollection
  isFinished?: boolean
  isStaking?: boolean
}> = ({ earningToken, collection, isFinished = false, isStaking = false }) => {
  const { t } = useTranslation()
  const background = isStaking ? 'dark' : 'cardHeader'

  const getHeadingPrefix = () => {
    return t('Stake')
  }

  const getSubHeading = () => {
    return t('Earn %symbol%', { symbol: earningToken.symbol })
  }

  return (
    <BgWrapper isFinished={isFinished} background={background}>
      <Wrapper>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex flexDirection="column">
            <Heading color={isFinished ? 'textDisabled' : 'text'} scale="lg">
              {`${getHeadingPrefix()} ${collection.name}`}
            </Heading>
            <Text color={isFinished ? 'textDisabled' : 'textSubtle'}>{getSubHeading()}</Text>
          </Flex>
          <NftCollectionImage collection={collection} width={64} height={64} />
        </Flex>
      </Wrapper>
    </BgWrapper>
  )
}

export default StyledCardHeader
