import { createSlice } from '@reduxjs/toolkit'
import nftPoolsConfig from 'config/constants/nftPools'
import { NftPoolsState, SerializedNftPool, AppThunk } from 'state/types'
import { fetchNftPoolsConfigurations, fetchNftPoolsRewards, fetchNftPoolsTotalStaking } from './fetchNftPools'
import { fetchNftExtensionPools } from './fetchNftExtensionPools'
import {
  fetchNftPoolsAllowance,
  fetchNftPoolsRegistrations,
  fetchUserNftBalances,
  fetchUserNftStakeBalances,
  fetchUserNftPendingRewards,
  fetchUserNftTokens,
} from './fetchNftPoolsUser'

const initialState: NftPoolsState = {
  data: [...nftPoolsConfig],
  userDataLoaded: false,
  userTokens: {
    data: [],
    userNftLoaded: false,
  },
}

// Thunks
export const fetchNftPoolsPublicDataAsync = (chainId: number, currentBlock: number) => async (dispatch) => {
  const configurations = await fetchNftPoolsConfigurations(chainId)
  const rewards = await fetchNftPoolsRewards(chainId)
  const totalStakings = await fetchNftPoolsTotalStaking(chainId)

  const liveData = nftPoolsConfig.map((pool) => {
    const configuration = configurations.find((entry) => entry.sousId === pool.sousId)
    const reward = rewards.find((entry) => entry.sousId === pool.sousId)
    const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId)
    const isPoolEndBlockExceeded =
      currentBlock > 0 && configuration?.endBlock && Number(configuration.endBlock) > 0
        ? currentBlock > Number(configuration.endBlock)
        : false
    const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

    return {
      ...configuration,
      ...reward,
      ...totalStaking,
      isFinished: isPoolFinished,
    }
  })

  dispatch(setNftPoolsPublicData(liveData))
}

export const fetchNftPoolsUserDataAsync =
  (chainId: number, account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchNftPoolsAllowance(account, chainId)
    const registrations = await fetchNftPoolsRegistrations(account, chainId)
    const stakingTokenBalances = await fetchUserNftBalances(account, chainId)
    const stakedBalances = await fetchUserNftStakeBalances(account, chainId)
    const pendingRewards = await fetchUserNftPendingRewards(account, chainId)

    const chainPools = nftPoolsConfig.filter((p) => p.chainId === chainId)
    const userData = chainPools.map((pool) => ({
      sousId: pool.sousId,
      isApproved: allowances[pool.sousId],
      isRegistered: registrations[pool.sousId],
      availableBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
    }))

    dispatch(setNftPoolsUserData(userData))
  }

export const fetchNftPoolsExtensionsDataAsync = () => async (dispatch, getState) => {
  const extensionData = await fetchNftExtensionPools()
  dispatch(setNftPoolsExtensionData(extensionData))
}

export const fetchNftUserTokensAsync =
  (account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const tokens = await fetchUserNftTokens(account, chainId)
    dispatch(setNftUserTokensData(tokens))
  }

export const updateUserNftPendingReward =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserNftPendingRewards(account, chainId)
    dispatch(updateNftPoolsUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }))
  }

export const updateUserNftAllowance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchNftPoolsAllowance(account, chainId)
    dispatch(updateNftPoolsUserData({ sousId, field: 'isApproved', value: allowances[sousId] }))
  }

export const updateUserNftBalance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserNftBalances(account, chainId)
    dispatch(updateNftPoolsUserData({ sousId, field: 'availableBalance', value: tokenBalances[sousId] }))
  }

export const updateUserNftStakedBalance =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserNftStakeBalances(account, chainId)
    dispatch(updateNftPoolsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }))
  }

export const updateUserNftRegistration =
  (sousId: number, account: string, chainId: number): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchNftPoolsRegistrations(account, chainId)
    dispatch(updateNftPoolsUserData({ sousId, field: 'isRegistered', value: stakedBalances[sousId] }))
  }

export const NftPoolsSlice = createSlice({
  name: 'NftPools',
  initialState,
  reducers: {
    setNftPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedNftPool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, ...livePoolData }
      })
    },
    setNftPoolsExtensionData: (state, action) => {
      const { pools }: { pools: SerializedNftPool[] } = action.payload

      pools.forEach((pool) => {
        const index = state.data.findIndex((p) => p.sousId === pool.sousId)
        if (index >= 0) {
          state.data[index] = { ...pool, ...state.data[index] }
        } else {
          state.data.unshift({ ...pool })
        }
      })
    },
    setNftPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.sousId === pool.sousId)
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    },
    setNftUserTokensData: (state, action) => {
      const tokensData = action.payload
      state.userTokens.data = tokensData
      state.userTokens.userNftLoaded = true
    },
    updateNftPoolsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
  // extraReducers: (builder) => {
  // },
})

// Actions
export const {
  setNftPoolsPublicData,
  setNftPoolsUserData,
  setNftUserTokensData,
  updateNftPoolsUserData,
  setNftPoolsExtensionData,
} = NftPoolsSlice.actions

export default NftPoolsSlice.reducer
