import { getRevuSpecialV1Contract } from 'utils/contractHelpers'

export interface RevuSpecialNftMintData {
  nftId: number | string
  lotteryId: string
  cursor: string
}

export interface NftClaim {
  canClaim: boolean
  mintData: RevuSpecialNftMintData
}

export const NO_CLAIM: NftClaim = {
  canClaim: false,
  mintData: null,
}

export const getRYIUClaim = async (
  account: string,
  variationId: number | string,
  lotteryId: string,
): Promise<NftClaim> => {
  const lotteryNftContract = getRevuSpecialV1Contract()

  if (lotteryId) {
    try {
      const passesContractCheck = await lotteryNftContract.canClaimNft1(account, lotteryId)

      if (passesContractCheck) {
        return {
          canClaim: true,
          mintData: { nftId: variationId, lotteryId, cursor: '0' },
        }
      }
    } catch (error) {
      console.error(`Failed to check canClaim for RYIU`, error)
      return NO_CLAIM
    }
  }

  return NO_CLAIM
}

export const getWatcheriumClaim = async (
  account: string,
  variationId: number | string,
  lotteryId?: string,
): Promise<NftClaim> => {
  const lotteryNftContract = getRevuSpecialV1Contract()

  try {
    const isWhitelisted = await lotteryNftContract.userWhitelistForNft2(account)
    if (isWhitelisted) {
      try {
        const passesContractCheck = await lotteryNftContract.canClaimNft2(account)
        if (passesContractCheck) {
          const lId = lotteryId || (await lotteryNftContract.startRound()).toString()
          return {
            canClaim: true,
            mintData: { nftId: variationId, lotteryId: lId, cursor: '0' },
          }
        }
      } catch (error) {
        console.error(`Failed to check canClaim for Watcherium`, error)
        return NO_CLAIM
      }
    }
  } catch (error) {
    console.error(`Failed to check whitelist for ${account}`, error)
    return NO_CLAIM
  }
  return NO_CLAIM
}
