import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Skeleton, Text } from '@unitydefi/uikit'
import { DeserializedNftPool } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { useUserNfts } from 'state/nftStaking/hooks'
import { getCurrentDailyTotalRewards } from 'views/NftStaking/helpers'
import BaseCell, { CellContent } from './BaseCell'

interface UserRewardCellProps {
  pool: DeserializedNftPool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const UserRewardCell: React.FC<UserRewardCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { earningToken } = pool

  const { nftTokens, userNftLoaded } = useUserNfts()
  const stakeInfos = useMemo(() => {
    if (!nftTokens) return undefined

    return nftTokens.filter((p) => p.stakingInfo?.poolId === pool.sousId).map((p) => p.stakingInfo)
  }, [nftTokens, pool.sousId])

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {pool.mode === 'rewardPerBlock' ? t('Your daily rewards') : t('Your daily rewards')}
        </Text>
        {userNftLoaded && nftTokens ? (
          <Text color="text">
            {getCurrentDailyTotalRewards(pool, stakeInfos).toFixed(2)} {earningToken.symbol} per day
          </Text>
        ) : (
          <Skeleton width="80px" height="16px" />
        )}
      </CellContent>
    </StyledCell>
  )
}

export default UserRewardCell
