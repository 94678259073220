import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserNftStakedBalance, updateUserNftBalance, updateUserNftPendingReward } from 'state/actions'
import { useNftRewardPoolContract, useNftStakingVaultContract } from 'hooks/useContract'
import getGasPrice from 'utils/getGasPrice'
import { fetchNftPoolsPublicDataAsync, updateUserNftRegistration } from 'state/nftStaking'
import useSelectedChain from 'hooks/useSelectedChain'
import { getGasLimit } from '../helpers'

const sousUnstake = async (chainId, stakingContract, tokenIds) => {
  const gasPrice = getGasPrice(chainId)
  const gasLimit = getGasLimit(await stakingContract.estimateGas.batchUnstake(tokenIds), 1.08, 150000)
  const tx = await stakingContract.batchUnstake(tokenIds, {
    gasPrice,
    gasLimit,
  })
  const receipt = await tx.wait()
  return receipt.status
}

const sousUnstakeV2 = async (chainId, stakingContract, tokenIds, isEmergency) => {
  const gasPrice = getGasPrice(chainId)
  const gasLimit = getGasLimit(await stakingContract.estimateGas.withdraw(tokenIds), 1.08, 150000)
  const tx = await stakingContract.withdraw(tokenIds, {
    gasPrice,
    gasLimit,
  })
  const receipt = await tx.wait()
  return receipt.status
}

// const sousEmergencyUnstake = async (sousChefContract: any) => {
//   const gasPrice = getGasPrice()
//   const tx = await sousChefContract.emergencyWithdraw({ gasPrice })
//   const receipt = await tx.wait()
//   return receipt.status
// }

const useNftUnstakePool = (sousId: number, mode: 'duration' | 'rewardPerBlock', enableEmergencyWithdraw = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const { chainId } = useSelectedChain()
  const stakingContract = useNftStakingVaultContract(sousId)
  const rewardContract = useNftRewardPoolContract(sousId)

  const handleUnstake = useCallback(
    async (tokenIds: number[]) => {
      if (mode === 'duration') await sousUnstake(chainId, stakingContract, tokenIds)
      else await sousUnstakeV2(chainId, rewardContract, tokenIds, enableEmergencyWithdraw)

      const blockNumber = await stakingContract.provider.getBlockNumber()
      dispatch(fetchNftPoolsPublicDataAsync(chainId, blockNumber))
      dispatch(updateUserNftRegistration(sousId, account, chainId))
      dispatch(updateUserNftStakedBalance(sousId, account, chainId))
      dispatch(updateUserNftBalance(sousId, account, chainId))
      dispatch(updateUserNftPendingReward(sousId, account, chainId))
    },
    [account, dispatch, enableEmergencyWithdraw, mode, rewardContract, sousId, stakingContract, chainId],
  )

  return { onUnstake: handleUnstake }
}

export default useNftUnstakePool
