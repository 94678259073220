import { Currency, Token } from '@pancakeswap/sdk'
import { ETHER_TOKENS } from 'config/constants/chains'

export function currencyId(currency: Currency, chainId: number): string {
  if (currency === ETHER_TOKENS[chainId]) return ETHER_TOKENS[chainId].symbol || 'BNB'
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export default currencyId
