import React from 'react'
import { Flex, Button, Text } from '@unitydefi/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { useGasPriceManager } from 'state/user/hooks'
import useSelectedChain from 'hooks/useSelectedChain'

const GasSettings = () => {
  const { t } = useTranslation()
  const { selectedChain } = useSelectedChain()
  const [gasPrice, setGasPrice] = useGasPriceManager(selectedChain.chainId)

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text color="textSubtle">{t('Default Transaction Speed (GWEI)')}</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap">
        <Button
          mt="4px"
          mr="4px"
          scale="sm"
          onClick={() => {
            setGasPrice(undefined)
          }}
          variant={!gasPrice ? 'contrast' : 'contrastDark'}
        >
          {t('Auto')}
        </Button>
        {selectedChain.gasPrices && selectedChain.gasPricesGwei ? (
          <>
            <Button
              mt="4px"
              mr="4px"
              scale="sm"
              onClick={() => {
                setGasPrice(selectedChain.gasPricesGwei.default)
              }}
              variant={gasPrice === selectedChain.gasPricesGwei.default ? 'contrast' : 'contrastDark'}
            >
              {t('Standard (%gasPrice%)', { gasPrice: selectedChain.gasPrices.default })}
            </Button>
            <Button
              mt="4px"
              mr="4px"
              scale="sm"
              onClick={() => {
                setGasPrice(selectedChain.gasPricesGwei.fast)
              }}
              variant={gasPrice === selectedChain.gasPricesGwei.fast ? 'contrast' : 'contrastDark'}
            >
              {t('Fast (%gasPrice%)', { gasPrice: selectedChain.gasPrices.fast })}
            </Button>
            <Button
              mr="4px"
              mt="4px"
              scale="sm"
              onClick={() => {
                setGasPrice(selectedChain.gasPricesGwei.instant)
              }}
              variant={gasPrice === selectedChain.gasPricesGwei.instant ? 'contrast' : 'contrastDark'}
            >
              {t('Instant (%gasPrice%)', { gasPrice: selectedChain.gasPrices.instant })}
            </Button>
          </>
        ) : undefined}
      </Flex>
    </Flex>
  )
}

export default GasSettings
