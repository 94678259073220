import { Collections, CollectionKey } from './types'

const collections: Collections = {
  [CollectionKey.REVOLUTIONIZE_UNITY]: {
    name: 'Revolution Unity',
    slug: 'revolution-unity',
    address: {
      56: '0xa15A9a4d3cacFD897C49B400e0Dda37d43eA0629',
      97: '',
    },
  },
  [CollectionKey.REVU_WARRIORS]: {
    name: 'REVU Warriors',
    slug: 'revu-warriors',
    address: {
      56: '0x7fE7c8c961D000aD1E05186Fc43382DB815428c8',
      97: '',
    },
  },
  // [CollectionKey.SQUAD]: {
  //   name: 'Unity Ventures Squad',
  //   description: "UnityVentures's first official generative NFT collection.. Join the squad.",
  //   slug: 'unitydefi-squad',
  //   address: {
  //     56: '',
  //     97: '0xEf12ef570300bFA65c4F022deAaA3dfF4f5d5c91',
  //   },
  // },
}

export default collections
