import basePoolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getMasterchefContract } from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { getRpcProvider } from 'lib/entities/providers'

// Pool 0, UV / UV is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
// const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB')
// const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB')
// const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)

const getPoolsConfig = async () => {
  const poolsConfig = basePoolsConfig

  const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB')
  const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB')
  const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)

  return { poolsConfig, nonBnbPools, bnbPools, nonMasterPools }
}

const masterChefContract = getMasterchefContract()

export const fetchPoolsAllowance = async (chainId: number, account) => {
  const { nonBnbPools } = await getPoolsConfig()
  const chainPools = nonBnbPools.filter((p) => p.chainId === chainId)
  const calls = chainPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress, chainId)],
  }))

  const allowances = await multicall(chainId, erc20ABI, calls)
  return chainPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (chainId: number, account) => {
  const rpcProvider = getRpcProvider(chainId)

  const { nonBnbPools, bnbPools } = await getPoolsConfig()
  const chainPools = nonBnbPools.filter((p) => p.chainId === chainId)

  // Non BNB pools
  const calls = chainPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(chainId, erc20ABI, calls)
  const tokenBalances = chainPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )

  // BNB pools
  const bnbBalance = await rpcProvider.getBalance(account)
  const bnbBalances = bnbPools.reduce(
    (acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(bnbBalance.toString()).toJSON() }),
    {},
  )

  return { ...tokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (chainId: number, account) => {
  const { nonMasterPools } = await getPoolsConfig()
  const chainPools = nonMasterPools.filter((p) => p.chainId === chainId)

  const calls = chainPools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(chainId, sousChefABI, calls)
  const stakedBalances = chainPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  // UV / UV pool
  const { amount: masterPoolAmount } = chainId === 56 ? await masterChefContract.userInfo('0', account) : { amount: 0 }

  return { ...stakedBalances, 0: new BigNumber(masterPoolAmount.toString()).toJSON() }
}

export const fetchUserPendingRewards = async (chainId: number, account) => {
  const { nonMasterPools } = await getPoolsConfig()
  const chainPools = nonMasterPools.filter((p) => p.chainId === chainId)

  const calls = chainPools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(chainId, sousChefABI, calls)
  const pendingRewards = chainPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  // UV / UV pool
  const pendingReward = chainId === 56 ? await masterChefContract.pendingUV('0', account) : { amount: 0 }
  return { ...pendingRewards, 0: new BigNumber(pendingReward.toString()).toJSON() }
}
