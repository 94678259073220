import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useRefresh from 'hooks/useRefresh'
import { getRpcProvider } from 'lib/entities/providers'
import {
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchUVVaultPublicData,
  fetchUVVaultUserData,
  fetchUVVaultFees,
  fetchPoolsStakingLimitsAsync,
  fetchPoolsExtensionsDataAsync,
} from '.'
import { State, DeserializedPool } from '../types'
import { transformPool } from './helpers'

export const useFetchPublicPoolsData = (chainId: number) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const rpcProvider = getRpcProvider(chainId)

  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await rpcProvider.getBlockNumber()
      dispatch(fetchPoolsPublicDataAsync(chainId, blockNumber))
    }

    fetchPoolsPublicData()
    dispatch(fetchPoolsStakingLimitsAsync(chainId))
  }, [dispatch, slowRefresh, chainId, rpcProvider])
}

export const useFetchExtensionPools = (chainId: number) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchPoolsExtensionsData = async () => {
      dispatch(fetchPoolsExtensionsDataAsync())
    }

    if (chainId === 56) fetchPoolsExtensionsData()
  }, [dispatch, slowRefresh, chainId])
}

export const useFetchUserPools = (account, chainId: number) => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account, chainId))
    }
  }, [account, dispatch, fastRefresh, chainId])
}

export const usePools = (): { pools: DeserializedPool[]; userDataLoaded: boolean } => {
  const { pools, userDataLoaded } = useSelector((state: State) => ({
    pools: state.pools.data,
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pools: pools.map(transformPool), userDataLoaded }
}

export const useFetchUVVault = (chainId: number) => {
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (chainId === 56) dispatch(fetchUVVaultPublicData())
  }, [dispatch, fastRefresh, chainId])

  useEffect(() => {
    if (chainId === 56) dispatch(fetchUVVaultUserData({ account }))
  }, [dispatch, fastRefresh, account, chainId])

  useEffect(() => {
    if (chainId === 56) dispatch(fetchUVVaultFees())
  }, [dispatch, chainId])
}

export const useUVVault = () => {
  const {
    totalShares: totalSharesAsString,
    pricePerFullShare: pricePerFullShareAsString,
    totalUVInVault: totalUVInVaultAsString,
    estimatedUVBountyReward: estimatedUVBountyRewardAsString,
    totalPendingUVHarvest: totalPendingUVHarvestAsString,
    fees: { performanceFee, callFee, withdrawalFee, withdrawalFeePeriod },
    userData: {
      isLoading,
      userShares: userSharesAsString,
      uvAtLastUserAction: uvAtLastUserActionAsString,
      lastDepositedTime,
      lastUserActionTime,
    },
  } = useSelector((state: State) => state.pools.uvVault)

  const estimatedUVBountyReward = useMemo(() => {
    return new BigNumber(estimatedUVBountyRewardAsString)
  }, [estimatedUVBountyRewardAsString])

  const totalPendingUVHarvest = useMemo(() => {
    return new BigNumber(totalPendingUVHarvestAsString)
  }, [totalPendingUVHarvestAsString])

  const totalShares = useMemo(() => {
    return new BigNumber(totalSharesAsString)
  }, [totalSharesAsString])

  const pricePerFullShare = useMemo(() => {
    return new BigNumber(pricePerFullShareAsString)
  }, [pricePerFullShareAsString])

  const totalUVInVault = useMemo(() => {
    return new BigNumber(totalUVInVaultAsString)
  }, [totalUVInVaultAsString])

  const userShares = useMemo(() => {
    return new BigNumber(userSharesAsString)
  }, [userSharesAsString])

  const uvAtLastUserAction = useMemo(() => {
    return new BigNumber(uvAtLastUserActionAsString)
  }, [uvAtLastUserActionAsString])

  return {
    totalShares,
    pricePerFullShare,
    totalUVInVault,
    estimatedUVBountyReward,
    totalPendingUVHarvest,
    fees: {
      performanceFee,
      callFee,
      withdrawalFee,
      withdrawalFeePeriod,
    },
    userData: {
      isLoading,
      userShares,
      uvAtLastUserAction,
      lastDepositedTime,
      lastUserActionTime,
    },
  }
}
