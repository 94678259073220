import React from 'react'
import { Button, useModal } from '@unitydefi/uikit'
import DeFiDashPanel from './DeFiDashPanel'

export default function DeFiDashButton() {
  const [onPresentModal] = useModal(<DeFiDashPanel />, false)

  return (
    <>
      <Button variant="text" color="white" onClick={onPresentModal} style={{ color: '#9F9F9F' }}>
        DeFiDash
      </Button>
    </>
  )
}
